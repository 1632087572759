import React, { useState } from 'react';
import '../pages/Style.css';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import { 
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';

interface ToggleComponentProps {
  title: JSX.Element;
  objectId: string;
  bodyData?: JSX.Element;
  isActive: boolean;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({title, objectId, bodyData, isActive = false, children}) => {
  
  const [toggleStatus, setToggleStatus] = useState(isActive);

  const toggleHandler = () => {
    setToggleStatus(prevToggle => !prevToggle);
  }

  return (
    <IonRow data-testid={`toggle-component-${objectId}`}>
      <IonCol class="mb-2 p-1 light-gr-bg">
        <IonRow class="gr-border">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pl-3 pb-1 white-bg">
            <IonRow>
              <IonCol sizeXs="10" sizeSm="10" sizeMd="10" sizeLg="10" sizeXl="10">
                <div data-testid='toggle-title' className="ion-text-left">{title}</div>
              </IonCol>
              <IonCol sizeXs="2" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2" class="white-bg">
                <IonIcon data-testid={`toggle-button`} class="toggle-icon hover-cursor" onClick={toggleHandler} icon={toggleStatus === false ? addCircleOutline : removeCircleOutline} size="large"></IonIcon>
              </IonCol>
              <IonCol class="pr-3">
                <div data-testid='toggle-div' className={toggleStatus === false ? 'inactive' : 'active'}>
                  {bodyData || children}
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default ToggleComponent;
