import qs from 'qs'
import validator from 'validator'
import * as AuthHelpers from './authServiceHelpers'
import * as AuthConst from '../authConstants'

type CodeFlowTypes = 'magic-link-code'
type HashTokenTypes = 'magic-link-hint' | 'error' | 'other' | CodeFlowTypes

export const getSignInWithMagicLink = async (hashParams: string) => {
  const hasParamList = hashParams.split('&')
  const idTokenHint = getParamOutOfHashList('id_token_hint', hasParamList)

  if(idTokenHint){
    return await resolveOauthMagicLinkPath(idTokenHint)
  }

  throw new Error('id_token_hint undefined')
}

export const getHashTokenType = (hashToken: string): HashTokenTypes => {
  if(hashToken.includes('error')){
    return 'error'
  }

  if(hashToken.includes('id_token_hint')){
    return 'magic-link-hint'
  }

  if(hashToken.includes('code') && hashToken.includes('state=ml')){
    return 'magic-link-code'
  }

  return 'other'
}

export const redirectToLogin = () => {
  window.location.href =  resolveOauthSignInLink()
}

export const resolveCodeFlowToPolicy = (flowType: CodeFlowTypes) => {
  switch(flowType){
      case 'magic-link-code':
        return AuthConst.POLICIES.magicLink
      default:
          return AuthConst.POLICIES.signIn
  }
}

//used by WEB, should not be needed for IOS or Android
export const requestRefreshToken = async (hashParams: string, hashType: CodeFlowTypes)=>{
    let codeVerifier = AuthHelpers.getCodeVerifier()
    let paramString = validator.ltrim(hashParams,'#')
    let paramsDirty = qs.parse(paramString) as any
    let params = AuthHelpers.validateCodeFlowParamKeys(paramsDirty)

    if(params === null){
      throw new Error('params provided were invalid')
    }
    
    const policy = resolveCodeFlowToPolicy(hashType)
    return await getRefreshToken(params.code, codeVerifier, policy)
}

export const parseError = (hashParam: string): boolean => {
  if(hashParam.startsWith('#')){
    hashParam = hashParam.slice(1)
  }
  const decodeHashParam = decodeURI(hashParam)
  const errorParts = decodeHashParam.split('&')
  const errorType = getParamOutOfHashList('error', errorParts)
  const errorDescription = getParamOutOfHashList('error_description', errorParts)

  return Boolean(errorType === 'access_denied' && errorDescription?.includes(AuthConst.ERROR_CODES.USER_CANCELLATION))
}

//helper
const getRefreshToken = async (authCode: string, codeVerifier: string, signInPolicy: Auth.LoginPolicies) => {
  const azureLoginParams = AuthHelpers.azureLoginParams()
  let refreshResponse = await (await fetch(AuthConst.resolveTokenEndpoint(signInPolicy), {
    method:'POST',
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    },
    body: `grant_type=authorization_code&client_id=${azureLoginParams.appId}&scope=${azureLoginParams.scope}&code=${authCode}&redirect_uri=${AuthConst.REDIRECT}&code_verifier=${codeVerifier}`
  })).json()

  if(refreshResponse.error){
    throw(refreshResponse.error)
  }

  return refreshResponse
}

const resolveOauthSignInLink = () => {
  if(process.env.REACT_APP_BUILD_ENV === 'PROD'){
    return 'https://login.pro.midlandtrust.com'
  }
  const queryParams = [
    ['client_id', AuthConst.APP_ID],
    ['nonce', 'defaultNonce'],
    ['response_type', 'id_token'],
    ['redirect_uri', encodeURIComponent(AuthConst.REDIRECT)],
    ['scope', 'openid'],
    ['prompt', 'login'],
  ]
  const queryParamString = queryParams.map(val=>(`${val[0]}=${val[1]}`)).join('&')

  return `${AuthConst.resolveSignInAuthority(AuthConst.POLICIES.signIn)}/oauth2/v2.0/authorize?${queryParamString}`
}

const resolveOauthMagicLinkPath = async (idTokenHint: string) => {
  const codeChallenge = await AuthHelpers.generateCodeChallenge()
  const queryParams = [
    ['id_token_hint',idTokenHint],
    ['client_id', AuthConst.APP_ID],
    ['response_type', 'code+id_token'],
    ['redirect_uri', encodeURIComponent(AuthConst.REDIRECT)],
    ['response_mode', 'fragment'],
    ['scope', encodeURIComponent(`openid offline_access https://${AuthConst.ENVIRONMENT.tennent}/${AuthConst.ENVIRONMENT.appId}/user_impersonation`)],
    ['code_challenge', codeChallenge],
    ['code_challenge_method', 'S256'],
    ['state', 'ml'] // state cannot be exposed to b2c policy
  ]
  const queryParamString = queryParams.map(val=>(`${val[0]}=${val[1]}`)).join('&')
  return `${AuthConst.resolveSignInAuthority(AuthConst.POLICIES.magicLink)}/oauth2/v2.0/authorize?${queryParamString}`
}

const getParamOutOfHashList = (paramKey: string,  hashParamList: string[]) => {
  const keyValuePair = hashParamList.find(val=>val.includes(paramKey))?.split('=')
  if(keyValuePair){
    return keyValuePair[1]
  }
  return undefined
}