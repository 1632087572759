import React from 'react';
import { downloadPenSignDocs } from '../helpers/CalloutHelpers';
import { IonButton } from '@ionic/react';

type PenSignDocumentProps = {
    accountNumber: string,
    disabled?: boolean,
    onClick?: ()=>Promise<void>|void,
    onDownloadFinished?: ()=>Promise<void>|void,
    onError?: ()=>Promise<void>|void,
}

const PenSignDocument: React.FC<PenSignDocumentProps> = (props) => {
    const {
        accountNumber, 
        disabled, 
        onClick, 
        onDownloadFinished, 
        onError
    } = props

    const downloadFile = async ()=>{
        try{
            onClick && await onClick()
            const objectUrl = await downloadPenSignDocs(accountNumber)
            const aTag = window.document.createElement('a')
            aTag.setAttribute('href', objectUrl || '')
            aTag.setAttribute('download', `signature-document-${accountNumber}.pdf`)
            aTag.click()
            onDownloadFinished && await onDownloadFinished()
        }catch(err){
            console.log(err)
            onError && await onError()
        }
    }

    return <IonButton onClick={downloadFile} disabled={disabled}> Download PDF Signature Documents </IonButton> 
}

export default PenSignDocument;