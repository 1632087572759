import { ErrorMessage } from '@hookform/error-message'
import Select, {StylesConfig} from 'react-select'
import { Controller } from 'react-hook-form'
import {
    IonInput,
    IonLabel,
    IonRow,
    IonSpinner,
    IonItem
} from '@ionic/react'
import { FieldErrors, RegisterOptions, Control, MultipleFieldErrors } from 'react-hook-form'
import { states } from '../../helpers/Utils'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export type AssetFundingInfoForm = sharedTypes.AssetFundingInfo & {
    creditAccountNumber?: string,
    creditAccountNumberSet?: undefined,
    stateSelect?: {value: string, label: string}
}

type InputTypeJestFix = CustomEvent<{ value: string }> & {target: {value?: string | null}}

type FromInputComponent = {
    editDisabled: boolean,
    onChangeCB?: (fieldOnChangeFunction: (event:InputTypeJestFix)=>void) => (event:InputTypeJestFix) => void,
    onKeyPressCB?: React.KeyboardEventHandler<HTMLIonInputElement>,
    errors?: FieldErrors<AssetFundingInfoForm>,
    rules?: Omit<RegisterOptions<AssetFundingInfoForm, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
    characterFilter?: RegExp,
    showSpinner?: boolean
    readOnly?: boolean
}
type StandardInputComponent = FromInputComponent & {
    name: keyof Omit<AssetFundingInfoForm, 'stateSelect'>,
    control: Control<Omit<AssetFundingInfoForm, 'stateSelect'>, object>,
}

type PicklistInputComponent = {
    name?: 'stateSelect',
    control: Control<AssetFundingInfoForm, object>,
    editDisabled: boolean,
    errors?: FieldErrors<AssetFundingInfoForm>,
    rules?: Omit<RegisterOptions<AssetFundingInfoForm, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
    showSpinner?: boolean
}

export const numberFilter = /\d/

export const textAreaFilter = /[^!@$%\^*+=~`"?><]/

const renderErrorMessages = (data: {message: string, messages?: MultipleFieldErrors}) => {
    return (data?.message && <IonRow style={{'color': 'red'}}>{data.message}</IonRow>) || <IonRow style={{'color': 'red'}}>Error on this field.</IonRow>
}

const fieldToLabel: {[key in keyof AssetFundingInfoForm]: string}  = {
    creditAccountNumber: 'Credit Account Number',
    creditAccountNumberSet: '',
    deliveryMethod: 'Delivery Method',
    address: 'Address',
    bankName: 'Bank Name',
    city: 'City',
    creditName: 'Credit Name',
    ffcDetail: 'FFC Detail',
    payableTo: 'Payable To',
    routingNumber: 'ABA',
    state: 'State',
    zip: 'Zip',
    pricePerUnit: 'Price Per Unit',
    priceDate: 'New Price Date'
}

export const StatePicklist: React.FC<PicklistInputComponent> = ({control, name, editDisabled, rules, errors})=>{
    const fieldName = name || 'stateSelect'
    const customStyles: StylesConfig = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'orange' : 'var(--ion-color-secondary-contrast)',
            background: state.isFocused ? 'var(--ion-color-primary)' : 'var(--ion-color-secondary)'
        }),
        container: (provided, state) => ({
            ...provided,
            height: '41.5px',
            width: '130px',
            color:'var(--ion-color-secondary-contrast)',
        }),

        menu: (provider, state) => ({
            ...provider,
            color: 'var(--ion-color-secondary-contrast)',
            background: 'var(--ion-color-secondary)',
            zIndex: 3,
        }),
        menuList: (provider, state) => ({
            ...provider,
            color: 'var(--ion-color-secondary-contrast)',
            background: 'var(--ion-color-secondary)',
            height: '130px'
        }),
        control: (provider, state) => ({
            ...provider,
            background: 'var(--ion-color-secondary)',
            borderColor: 'var(--ion-color-secondary)',
            color: 'var(--ion-color-secondary-contrast)',
        }),
        placeholder: (provider, state) => ({
            ...provider,
            color: editDisabled ? 'grey' : 'var(--ion-color-medium-shade)',
        }),
        input: (provider, state) => ({
            ...provider,
            color: editDisabled ? 'grey' : 'var(--ion-color-secondary-contrast)',
        }),
        singleValue: (provider, state) => ({
            ...provider,
            color: editDisabled ? 'var(--ion-color-medium-shade)' : 'var(--ion-color-secondary-contrast)',

        }),
        valueContainer: (provider, state) => ({
            ...provider,
            color: editDisabled ? 'grey' : 'var(--ion-color-secondary-contrast)',
        }),
        group: (provider, state) => ({
            ...provider,
            color: 'var(--ion-color-secondary-contrast)',
        }),
    }
    return (
        <Controller
        control={control}
        name={fieldName}
        rules={rules} 
        render={(props)=>(
                <>
                    <IonRow class='mt-1 mb-1'>
                        <IonLabel>State: </IonLabel>
                    </IonRow>
                    <IonRow>
                        <Select isDisabled={editDisabled} placeholder='State' styles={customStyles} className='gr-border' name={props.field.name} isMulti={false} isClearable={true} isSearchable={true} value={props.field.value} onChange={props.field.onChange} options={states.map(state=>({label: state, value: state}))}/>
                    </IonRow>
                    <IonRow>
                        <ErrorMessage name={fieldName} errors={errors} render={renderErrorMessages}/>
                    </IonRow>
                </>
                )
            }
        />
    )
}

export const StandardUserInput: React.FC<StandardInputComponent>  = ({control, name, editDisabled, rules, errors, onChangeCB, onKeyPressCB, characterFilter, showSpinner, readOnly})=>
    {
    const label = fieldToLabel[name]

    return <Controller
        data-testid={`${name}-label`}
        control={control}
        name={name}
        rules={rules}
        render={({field})=>
        <>
            <IonRow class='mt-1 mb-1'>
                <IonLabel  data-testid={`${name}-label`}>{`${label}: `}</IonLabel>
            </IonRow>
            <IonRow>
                {name === 'pricePerUnit' && <IonLabel style={{'paddingTop': '10px','paddingRight': '5px'}}>$</IonLabel>}
                <IonInput class='gr-border pl-1 pr-1 pb-1' readonly={readOnly} type={'text'} data-testid={`${name}-input`} value={field.value} ref={field.ref} name={field.name}
                    onIonChange={(event)=>{
                            const eventCasted = event as InputTypeJestFix
                            if(!eventCasted?.target?.value){
                                eventCasted.target.value = event.detail.value
                            }
        
                            onChangeCB ? onChangeCB(field.onChange)(eventCasted) : field.onChange(eventCasted)
                        }
                    } 
                    onKeyPress={(event)=>{
                            const currentValue = event.currentTarget.value
                            if(event.currentTarget.type === 'text'){
                                if(characterFilter && !characterFilter.test(event.key)){
                                    return event.preventDefault()
                                }
                                if(typeof currentValue === 'string'){
                                    if(rules?.maxLength && currentValue.length >= rules.maxLength){
                                        event.preventDefault()
                                    }
                                }

                            }
            
                            if(event.currentTarget.type === 'number' && rules?.max){
                                if(typeof currentValue === 'number'){
                                    if(currentValue >= Number(rules.max)){
                                        event.preventDefault()
                                    }
                                }
                                
                            }
            
                            (onKeyPressCB && onKeyPressCB(event))
                        }
                    }
                    disabled={editDisabled}
                />
                {showSpinner && <IonSpinner />}
            </IonRow>
            <IonRow>
                <ErrorMessage name={name} errors={errors} render={renderErrorMessages}/>
            </IonRow>
        </>
        }
    />}


type RoutingNumberInputType = {
    control: Control<Omit<AssetFundingInfoForm, 'stateSelect'>, object>,
    editDisabled: boolean,
    errors?: FieldErrors<AssetFundingInfoForm>,
    routingNumberSpinner?: boolean
    bankName?: string,
    fieldErrors: FieldErrors
}

export const RoutingNumberInput: React.FC<RoutingNumberInputType> = ({control, editDisabled, bankName, fieldErrors, routingNumberSpinner}) => {
    return <StandardUserInput control={control} editDisabled={editDisabled} errors={fieldErrors} name='routingNumber' rules={{
                pattern:{
                    value: /^\d{9}$/,
                    message: 'Routing number must be 9 digits.'
                },
                required: 'Routing number required.',
                validate: ()=>{
                    if(!bankName || bankName.length === 0){
                        return 'ABA must be valid.'
                    }
                    return true 
                },
                maxLength: 9
            }}
            onKeyPressCB={(event)=>{
                if(window.getSelection && Number(window.getSelection()?.toString()?.length) > 0 && Number(event.currentTarget.value?.toString().length) >= 9){
                    const selectedText = window.getSelection()?.toString()
                    const currentValue = event.currentTarget.value?.toString()
                    if(selectedText && currentValue){
                        event.currentTarget.value = currentValue.replace(selectedText, event.key)
                    } 
                    event.preventDefault()
                }
                return true
            }}
            characterFilter={numberFilter}
            showSpinner={routingNumberSpinner}
        />
}

type DateInputType = {
    control: Control<Omit<AssetFundingInfoForm, 'stateSelect'>, object>,
    name: 'priceDate',
    editDisabled: boolean,
    errors?: FieldErrors<AssetFundingInfoForm>,
    previousPriceDate?: string,
    dirtyPrice?: boolean
}


export const PriceDateInput: React.FC<DateInputType> = ({control, name, editDisabled, errors, previousPriceDate, dirtyPrice}) => {
    const convertStringDateToDate = (dateString?: string | undefined | null) => {
        if (dateString) {
            return new Date(dateString.replaceAll('-', '/'))
        }
        return null
    }

    return <Controller
        control={control}
        name={name}
        render={({field: {value, name, onChange}}) =>
            <>
                <IonRow class='mb-1'>
                    <IonLabel style={{'paddingTop': '10px', 'paddingRight': '5px'}}>{`${fieldToLabel[name]}: `}</IonLabel>
                </IonRow>
                <IonRow>
                    <IonItem class='gr-border'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker error={false} disabled={editDisabled}
                                data-testid={`${name}-input`}
                                name={name} disableFuture={true} placeholder='mm/dd/yyyy' value={convertStringDateToDate(value)} format='MM/dd/yyyy' animateYearScrolling={true} InputProps={{ disableUnderline: true }} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={(event) => {
                                    if (event) {
                                        const year = Number(event.getFullYear()), month = (Number(event.getMonth()) + 1), day = Number(event.getDate());
                                        onChange(`${year}-${month}-${day}`);
                                    }
                                }}/>
                        </MuiPickersUtilsProvider>
                    </IonItem>
                </IonRow>
                <IonRow>
                    <ErrorMessage name={name} errors={errors} render={renderErrorMessages}/>
                </IonRow>
            </>
        }
        rules={{
            validate: {
                afterPreviousDate: (value) => {
                    const inputDate = convertStringDateToDate(value);
                    const previousDate = convertStringDateToDate(previousPriceDate);
                    const validation = () => {
                        if (inputDate && previousDate) {
                            if (dirtyPrice) {
                                return inputDate > previousDate
                            } else {
                                return inputDate >= previousDate
                            }
                        }
                    }
                    return validation() ? undefined : 'We are not able to accept the date entered. The New Price Date must be more recent than the Current Price Date. To fix historical price values and dates, please call our Institutional Team at (239) 333-1034.'
                },
                beforeCurrentDate: (value) => {
                    const inputDate = convertStringDateToDate(value);
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);
                    const validation = inputDate !== null && inputDate < currentDate;
                    return validation ? undefined : 'Please select a date in the past. We are not able to use today\'s date or dates in the future.'
                }
            }
        }}
    />
}