import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonMenu, IonHeader, IonContent, IonList, IonToolbar, IonTitle, IonItem } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { generateNewIvestmentLink } from './helpers/Utils'
import Apply from './pages/Apply';
import Main from './pages/Main';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import SessionContext from './helpers/SessionContext';

const App: React.FC = () => {
  const userSession = React.useContext(SessionContext)

  return (
    <IonApp>
      <IonMenu contentId="main-content">
        <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem onClick={()=>window.open(generateNewIvestmentLink(userSession.user?.contactId || ''))}>Create New Asset</IonItem>
          <IonItem onClick={()=>userSession.logout()}>Log Out</IonItem>
        </IonList>
      </IonContent>
      </IonMenu>
      <IonReactRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route exact path="/apply/:onepathLink" render={()=><Apply/>} />
          <Route path="/:comp" render={()=> <Main/>}/>
        </Switch>
      </IonReactRouter>
    </IonApp>
)};

export default App;
