import React from 'react'
import { 
    IonRow
} from '@ionic/react';
import ToggleComponent from '../ToggleComponent';
import AssetTransactionGroup, { AssetGroupTitle } from '../AssetTransactionGroup/AssetTransactionGroup'; 
import { alterOnepathURL } from '../../helpers/component_helpers/homeHelpers'
type InputProps = {
    assetState:any, 
    updateAssetState:any, updateModalState:any, showLoading:any
}
const ListAssetGroups: React.FC<InputProps> = ({assetState, updateAssetState, updateModalState, showLoading }) =>{
    return assetState?.assetList?.length > 0 ? assetState.assetList.map((assetTransactionGroupState: any, assetIndex:any) => {
          const onePathLink = alterOnepathURL(assetTransactionGroupState.asset.onePathLink)
          return assetTransactionGroupState && 
          <ToggleComponent key={assetIndex} isActive={(assetIndex === 0) ? true : false} title={<AssetGroupTitle onePathLink={onePathLink} asset={assetTransactionGroupState.asset} updateModalState={updateModalState} updateAssetState={updateAssetState} transactions={assetTransactionGroupState.transactionList}/>} objectId={assetTransactionGroupState.asset.id}>
            <AssetTransactionGroup
              assetTransactionGroupState={assetTransactionGroupState}
              index={assetIndex}
              updateAssetState={updateAssetState}
              updateModalState={updateModalState}
              onePathLink={onePathLink}
            />
          </ToggleComponent>
        }) : 
        !showLoading && <IonRow data-testid='no-assets'>
          <p>No Assets Available to View</p>
        </IonRow>
}

export default ListAssetGroups