import axios, {CancelToken} from 'axios'

export const getUserAssets = () => {
    return axios.get<clientApiResponse.getAssets>('/api/assets')
}

export const getTransactionNotes = async (transactionId: String) => {
    let notesResponse = await axios.get<clientApiResponse.getNote>(`/api/note/${transactionId}`)
    if(notesResponse.data?.status === 'ok'){
        let notes = notesResponse.data.body;
        return notes
    }

    return ['No notes available.'];
}

export const getBankInfo = async (routingNumber: string, cancelToken?: CancelToken):Promise<{type: 'ACH'|'Domestic Wire', name: string} | undefined> => {
   if(routingNumber.length !== 9){
       return undefined
   }
   
   let result = await axios.get<clientApiResponse.getBank>(`/api/bank/${routingNumber}`,{cancelToken})
   if(result.data.status === 'ok'){
       return result.data.body
   }
   return undefined
}

export const updateAsset = async (assetId: string, fundingInfo: sharedTypes.AssetFundingInfo) => {
    return await axios.post<clientApiResponse.updateAsset>('/api/asset',{
        id: assetId,
        ...fundingInfo
    })
}

export const uploadFileToSF = async (data: FileUploadRequest) => {
    return await axios.post('/api/upload', data)
}

export const sendEsignToClient = async (account_number?: string) => {
    if(!account_number){
        return null
    }
    return await axios.post('/api/sendESignLink', {account_number})
}

export const sendResumeLinkToClient = async (account_number?: string) => {
    if(!account_number){
        return null
    }
    return await axios.post('/api/sendResumeSessionEmail', {account_number})
}

export function downloadPenSignDocs(accountNumber: string){
    return new Promise<string|undefined|null>((resolve, reject) => {
        const errorMessage = 'Error downloading pen sign docs'; 
        const xhr = new XMLHttpRequest();
        
        xhr.open('GET', `/api/penSignDocs/${accountNumber}`, true);
        xhr.responseType = "arraybuffer";
        xhr.withCredentials = true;
        
        console.log()
        xhr.setRequestHeader('X-XSRF-TOKEN', document.cookie.split('=')[1])

        xhr.onload = function () {
            if (this.status === 200) {
                var blob = new Blob([xhr.response], {type: "application/pdf"});
                var objectUrl = URL.createObjectURL(blob);
                resolve(objectUrl);
            }
            if (this.status !== 200) {
                reject(errorMessage);
            }
        };

        xhr.addEventListener("error", (ev) => {
            reject(errorMessage);
        })

        xhr.send();
    }) 
}

export const updateAppStatus = async (app_id: string, status: ApplicationStatus) => {
    const body:clientApiRequest.updateAppStatus = {app_id, status}
    return await axios.patch('/api/applicationStatus', body)
}