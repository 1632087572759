import React, { useEffect, useState } from 'react'
import {
    IonButton,
    IonSpinner,
    IonRow,
    IonLabel,
    IonInput
} from '@ionic/react'
import { getBankInfo, updateAsset } from '../../helpers/CalloutHelpers'
import { useForm, useWatch, SubmitHandler } from 'react-hook-form'
import axios, { CancelTokenSource } from 'axios'
import { StandardCard, states } from '../../helpers/Utils'
import { AssetFundingInfoForm, StandardUserInput, StatePicklist, RoutingNumberInput, numberFilter, textAreaFilter, PriceDateInput } from './AssetDetailHelpers'

const AssetDetail: React.FC<{ asset: Asset, close: ()=>void, updateFundingInfoState: (id: string, fundingInfo: sharedTypes.AssetFundingInfo)=>void, accountNumber: string }> = ({asset, close, updateFundingInfoState, accountNumber }) =>{
    const [routingNumberSpinner, setRoutingNumberSpinner] = useState(false)
    const [submittingSpinner, setSubmittingSpinner] = useState<boolean>(false)
    const [editDisabled, setEditDisabled] = React.useState<boolean>(true)
    const [showPriceDate, setShowPriceDate] = useState<boolean>(false)
    const [dirtyPrice, setDirtyPrice] = useState<boolean>(false)

    const previousPricePerUnit: number | undefined = asset.fundingInfo?.pricePerUnit;
    const previousPriceDate: string | undefined = asset.fundingInfo?.priceDate;

    const formattedPreviousPriceDate = () => {
        if (previousPriceDate) {
            const splitPreviousPriceDate = previousPriceDate.split('-');
            return `${splitPreviousPriceDate[1]}/${splitPreviousPriceDate[2]}/${splitPreviousPriceDate[0]}`
        }
    }

    const resolveDefaults: () => AssetFundingInfoForm = () => {
        const state =  states.find(val=>val===asset?.fundingInfo?.state)
        return {
           ...asset.fundingInfo,
           creditAccountNumber: (asset.fundingInfo?.creditAccountNumberSet && '****************') || undefined,
           creditAccountNumberSet: undefined,
           stateSelect: (state && {label: state, value: state}) || undefined,
           deliveryMethod: asset.fundingInfo?.deliveryMethod === 'Domestic Wire' ? 'Domestic Wire' : 'ACH'
       }
    }

    const { control, setValue, setError, clearErrors, formState, reset, handleSubmit } = useForm<AssetFundingInfoForm>({
       defaultValues: resolveDefaults()
    })

    const bankName = useWatch({
       control,
       name: 'bankName'
    })

    const deliveryMethod = useWatch({
       control,
       name: 'deliveryMethod'
    })

    const routingNumber = useWatch({
       control,
       name: 'routingNumber'
    })

    const pricePerUnit = useWatch({
        control,
        name: 'pricePerUnit'
    })

    useEffect(() => {
        if (pricePerUnit != previousPricePerUnit) {
            setDirtyPrice(true)
        } else {
            setDirtyPrice(false)
        }
    }, [pricePerUnit, previousPricePerUnit])

    useEffect(() => {
        if (editDisabled) {
            setShowPriceDate(false);
        } else {
            setShowPriceDate(true);
        };
    }, [editDisabled]);

    useEffect(()=>{
        let cancelToken: CancelTokenSource | undefined
        if(!formState.dirtyFields.routingNumber && formState.dirtyFields.bankName){
            setValue('bankName', asset.fundingInfo?.bankName)
            clearErrors('routingNumber')
        }
        if(routingNumber?.length === 9 && formState.dirtyFields.routingNumber){
            cancelToken = axios.CancelToken.source()
            setRoutingNumberSpinner(true)
            getBankInfo(routingNumber, cancelToken.token).then(val=>{
                setValue('bankName', val?.name || '')
                val?.type && setValue('deliveryMethod', val.type)

                if(!val){
                    setError('routingNumber', {
                        message: 'ABA must be valid',
                        type: 'validate'
                    })
                }else{
                    clearErrors('routingNumber')
                }
            }).catch(err=>{
                if(axios.isCancel(err)){
                    return
                }
                setValue('bankName', '')
            }).finally(()=>{
                setRoutingNumberSpinner(false)
            })
        }
        return ()=>cancelToken?.cancel()
   },[routingNumber, setValue, formState.dirtyFields.routingNumber, formState.dirtyFields.bankName, asset.fundingInfo?.bankName, clearErrors, setError])

    const onSubmit: SubmitHandler<AssetFundingInfoForm> = (data)=>{
        setEditDisabled(true)
        setSubmittingSpinner(true)
        if(data.creditAccountNumber && data.creditAccountNumber.includes('*')){
            data.creditAccountNumber = undefined
        }
        data.state = data.stateSelect?.value
        updateAsset(asset.id, data).then(async (result)=>{
            if(result.data.status === 'ok'){
                updateFundingInfoState(asset.id, result.data.body)
            }
            close()
        }).catch(err=>{
            console.error(err)
        }).finally(()=>{
            setSubmittingSpinner(false)
        })
    }

    const EditButton: React.FC = () => (
        <IonButton
            style={{'height': '25px', flex: 1}}
            disabled={submittingSpinner}
            data-testid='edit-button'
            onClick={()=>{
            setShowPriceDate(true);
            setEditDisabled((val)=>!val) 
            !editDisabled && reset()
        }}>
            {editDisabled ? 'Edit' : 'Cancel Change'}
        </IonButton>
    )

    const SubmitButton: React.FC = () => {
        return editDisabled ? ((submittingSpinner && <IonSpinner/>) || null) : <><IonButton
            style={{'height': '25px', flex: 1}}
            data-testid='submit-button'
            disabled={Object.keys(formState.dirtyFields).length === 0}
            onClick={
                ()=>{
                    handleSubmit(onSubmit,(errors)=>{
                        console.error(errors)
                    })()
                }
            }
            >
            Submit
        </IonButton></>
    }

    return <>
    <StandardCard>
        <form data-testid='form'>
        <IonRow>
            <IonLabel><b>Account #: </b>{accountNumber}</IonLabel>
        </IonRow>
        <RoutingNumberInput control={control} editDisabled={editDisabled} fieldErrors={formState.errors} bankName={bankName} routingNumberSpinner={routingNumberSpinner}/> 
        <StandardUserInput control={control} readOnly={true} editDisabled={editDisabled} errors={formState.errors} name='bankName'
            rules={{
                required: 'Given ABA does not match a known bank. Please try another ABA.'
            }}
        /> 
        {bankName &&
        <>
            <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors}  name='creditName'
                rules={{maxLength: 85}}
                characterFilter={textAreaFilter}
            /> 
            <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='creditAccountNumber'
                rules={{ maxLength: 16 }} 
                characterFilter={numberFilter}
                onKeyPressCB={(event)=>{
                    if(typeof event.currentTarget.value === 'string' && event.currentTarget.value.includes('*')){
                        event.preventDefault()
                        event.currentTarget.value = event.key 
                    }
                }}
            /> 
            {deliveryMethod === 'Domestic Wire' && <>
                <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='payableTo'
                    rules={{maxLength: 80}}
                    characterFilter={/[\w\s,]/}
                />
                <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='address'
                    rules={{maxLength: 80}}
                    characterFilter={textAreaFilter}
                />
                <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='city'
                    rules={{maxLength: 55}}
                    characterFilter={/[\w\s.,'\-]/}
                />
                <StatePicklist control={control} editDisabled={editDisabled} errors={formState.errors}/>
                <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='zip'
                    rules={{maxLength: 5}}
                    characterFilter={numberFilter}
                />
                <StandardUserInput  control={control} editDisabled={editDisabled} errors={formState.errors} name='ffcDetail'
                    rules={{maxLength: 35}}
                    characterFilter={textAreaFilter}
                />
            </>
            }
        </>}
        <StandardUserInput control={control} editDisabled={editDisabled} errors={formState.errors} name='pricePerUnit'
            rules={{
                maxLength: 16,
                validate: {
                    amountGreaterThanZero: (value) => ((value && (+value > 0)) ? undefined : 'Please provide a Price per Unit greater than 0.'),
                }
            }}
            characterFilter={/^[0-9]*\.?[0-9]{0,2}?$/}
        />
        {!showPriceDate &&
            <>
                <IonRow class='mb-1'>
                    <IonLabel style={{'paddingTop': '10px','paddingRight': '5px'}}>Price Date: </IonLabel>
                </IonRow>
                <IonRow>
                    <IonInput class='gr-border pl-1 pr-1 pb-1' readonly={true} disabled={editDisabled} type={'text'} value={formattedPreviousPriceDate()}></IonInput>
                </IonRow>
            </>
        }
        {showPriceDate &&
            <PriceDateInput control={control} name='priceDate' editDisabled={editDisabled} errors={formState.errors} previousPriceDate={previousPriceDate} dirtyPrice={dirtyPrice}/>
        }
        </form>
    </StandardCard>
    <IonRow>
        <SubmitButton/>
        <EditButton/>
    </IonRow>
    </>
}

export default AssetDetail