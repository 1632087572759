import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router'
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import Header from '../components/Header';
import './Style.css';

const buildTimeEnv: 'PROD'|'QA' = process.env.REACT_APP_BUILD_ENV === 'PROD' ? 'PROD' : 'QA' //default to QA
const defaultApplicationUrl = buildTimeEnv === 'PROD' ? 'https://apply.midlandtrust.com' : 'https://testapply.midlandtrust.com' 
const appURL = process.env.REACT_APP_APPLICATION_URL || defaultApplicationUrl

const Apply: React.FC = () => {
    const history = useHistory();
    const { onepathLink } = useParams<{ onepathLink: string }>();

    window.addEventListener("message", function (event) {
        if(event.origin !== appURL){
            return
        }

        if(event.data !== 'close-iframe'){
            return event
        }

        let frameToRemove = document.getElementById("iframe");
        if (frameToRemove) {
            frameToRemove.parentNode?.removeChild(frameToRemove);
            document.body.style.overflow = "inherit";
        }
        history.replace('/home');
    });

    const IFrameApp: React.FC = () => {
        const onePathLinkDecoded = atob(decodeURIComponent(onepathLink))
        return <iframe 
            sandbox='allow-scripts allow-same-origin allow-downloads'
            src={onePathLinkDecoded} title='test' style={{height: "99%", width: "100%", border: "none", overflow: "hidden"}}
        />
    }
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    
    return ( 
        <IonPage id="main-content"> 
            <IonHeader>
                <Header />
            </IonHeader>

            <IonContent className='iframe-wrapper' ref={contentRef} >
                {<IFrameApp />}
            </IonContent>
        </IonPage>
    )
}

export default Apply;