import React, { MouseEventHandler, useContext, useState } from 'react'
import { STATUS_TABS } from '../../helpers/component_helpers/sharedHelpers';
import {
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRouterLink,
  IonToast,
} from '@ionic/react';
import SessionContext from '../../helpers/SessionContext';
import helpCircleOutline from '../../images/help-circle-outline.svg';
import  * as homeHelpers from '../../helpers/component_helpers/homeHelpers'
import { 
  SortingIcon,
  TransactionAmountHeader,
  TransactionDateHeader
} from './AssetTransactionGroupSubModules'
import TransactionListItem from './TransactionListItem'
import AssetDetail from '../AssetDetail/AssetDetail';
import FileUpload from '../FileUpload/FileUpload';

type Params = {
  assetTransactionGroupState: CusipAndTransactionStatusList,
  index: number,
  updateAssetState: React.Dispatch<homeHelpers.assetActions>
  updateModalState: React.Dispatch<homeHelpers.ModalActions>
  onePathLink: string
}

const AssetTransactionGroup: React.FC<Params> = ({assetTransactionGroupState, index, updateAssetState, updateModalState, onePathLink}) =>{
  return (<IonGrid data-testid={`asset-body-${assetTransactionGroupState.asset.id}`}>
        <IonRow class="gr-border mb-2 ion-justify-content-around">
          {( STATUS_TABS.map((tab) => {
            let tabSpined = tab.replace(' ', '-')
            return(
              <IonCol key={tab} size="3" class="">
              <IonItem data-testid={`status-tab-${tabSpined}`} 
                lines="none"
                onClick={()=>updateAssetState({type:'chooseTab', tab, index})}
                class="ion-text-center hover-cursor" color={(tab === assetTransactionGroupState.transactionSortingStatus.currentTab) ? 'primary' : 'secondary'}>
                <IonLabel>{tab}</IonLabel>
              </IonItem>
            </IonCol>
            )
          }
          ))}
        </IonRow>
        <TableHeaders assetTransactionGroupState={assetTransactionGroupState} updateAssetState={updateAssetState} updateModalState={updateModalState} index={index} onePathLink={onePathLink}/>
        {assetTransactionGroupState.transactionList.reduce<JSX.Element[]>((elementArray, trans) => {
          let relatedTab = homeHelpers.statusToTab(trans.status, trans.type)
          if(relatedTab === assetTransactionGroupState.transactionSortingStatus.currentTab){
            let transactionElement = (
              <TransactionListItem
                key={trans.id} 
                currentTab={assetTransactionGroupState.transactionSortingStatus.currentTab} 
                trans={trans}
                updateModalState={updateModalState}
                onePathLink={onePathLink}
              />
            )
            elementArray.push(transactionElement)
          }
          return elementArray
        },[])}
    </IonGrid>
  )
}

export const AssetGroupTitle: React.FC<{
    asset: Asset,
    onePathLink: string,
    updateModalState: React.Dispatch<homeHelpers.ModalActions>,
    updateAssetState: React.Dispatch<homeHelpers.assetActions>
    transactions: sharedTypes.TransactionStatusItem[]
  }> = ({asset, updateModalState, updateAssetState, transactions, onePathLink}) =>{

  const TOAST_DURATION = 4000
  const [toastState, setToastState] = useState<{message: string, type: 'success'|'error'}>()

  const userSession = useContext(SessionContext)

  const updateFundingInfoState = (id: string, fundingInfo: sharedTypes.AssetFundingInfo) =>{
    updateAssetState({
      type:'updateFundingInfo',
      assetId: id,
      fundingInfo
    })
  } 

  const renderAssetDetails = ()=>{
    const closeModal = () => updateModalState({type:'close'})
    updateModalState({
      type: 'renderElement',
      element: <AssetDetail asset={asset} close={closeModal} updateFundingInfoState={updateFundingInfoState} accountNumber={transactions[0]?.accountNumber || ''} />,
      title: asset.name
    })
  }

  const renderFileUpload = ()=>{
    const lockModal = (locked: boolean) => updateModalState({type: 'lockModal', value: locked})
    const finishUpload = () => {
      updateModalState({type: 'forceClose'})
      setToastState({
        message: `Successfully uploaded files.`,
        type: 'success'
      })
      setTimeout(()=>setToastState(undefined), TOAST_DURATION)
    }
    updateModalState({
      type: 'renderElement',
      element: <FileUpload finishUpload={finishUpload} cusipName={asset.name} userInfo={userSession.user} setLocked={lockModal}/>,
      title: 'Secure Upload'
    })
  }

  /**
   * todo
   * Centralize toast control w/ modal control
   */
  return <>
    <IonToast duration={TOAST_DURATION} cssClass='AssetGroupToast' isOpen={Boolean(toastState)} color={toastState?.type} position={'top'} message={toastState?.message}/>
    <IonRow>
      <h1 data-testid='asset-name'>
        {asset.name}
      </h1>
      <IonRouterLink data-testid='view-details' onClick={renderAssetDetails} className='hover-cursor' style={{'paddingTop': '28px', 'paddingLeft': '10px'}}>View Details</IonRouterLink>
      <div style={{'paddingTop': '28px', 'paddingLeft': '10px'}}>|</div>
      <IonRouterLink data-testid='upload-file' onClick={renderFileUpload} className='hover-cursor' style={{'paddingTop': '28px', 'paddingLeft': '10px'}}>Upload File</IonRouterLink>
      {
        /**
        * Route link might change, for now I'm base64 encoding the link
          */
        onePathLink && <>
          <div style={{'paddingTop': '28px', 'paddingLeft': '10px'}}>|</div>
          <IonRouterLink routerLink={`/apply/${homeHelpers.encodeApplyLink(onePathLink || '')}`} data-testid='upload-file' className='hover-cursor' style={{'paddingTop': '28px', 'paddingLeft': '10px'}}>Invite Client</IonRouterLink>
        </>
      }

    </IonRow>
    {asset.onePathLink && 
    <h3 data-testid='asset-onepath'> 
      {`One Path: `}
      <a target='_blank' rel='noreferrer' style={{color: 'var(--ion-color-primary)'}} className='pl-1' href={onePathLink} onClick={()=>{
          onePathLink && navigator.clipboard.writeText(onePathLink)
        }}>
          {onePathLink}
      </a>
    </h3>
    }
  </>
}

type SetHeaderGenerator = (header: string) => MouseEventHandler<HTMLIonIconElement>

const TableHeaders: React.FC<Params> = (props) => {
  const currentTab = props.assetTransactionGroupState.transactionSortingStatus.currentTab
  const completeOrInprogressActive = (currentTab === 'Complete' || currentTab === 'In Progress')
  const smallSize = completeOrInprogressActive ? '2' : '4'

  const setHeaderTooltip: SetHeaderGenerator = (header: string):MouseEventHandler<HTMLIonIconElement> => (event) => {
    event.stopPropagation()
    /**
     * Todo
     * Move modal into context or setup redux context
     * 
     * Modal should be able to share controls with child elements without needing to pass down the controller through a chain of props
     */
    props.updateModalState({
      type: 'renderHeaderTip',
      header,
      activeTab: currentTab
    })
  }
  const {assetTransactionGroupState, updateAssetState, index} = props
  return (
    <IonRow class="headers">
      <IonCol data-testid='investor-name-column' class="table-header hover-cursor" onClick={()=>updateAssetState({type:'sort', column: 'clientName', index})} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
        <p>
          <b data-testid='sort-column'>Investor Name</b> 
          <IonIcon data-testid='view-tooltip' onClick={setHeaderTooltip('Investor Name')} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
          <SortingIcon render={'clientName' === assetTransactionGroupState.transactionSortingStatus.sortedColumn} currentSortingDir={assetTransactionGroupState.transactionSortingStatus.sortDirection}/>
        </p>
      </IonCol>
      <IonCol data-testid='account-type-column' class="table-header hover-cursor" onClick={()=>updateAssetState({type:'sort', column: 'accountType', index})} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
        <p>
          <b data-testid='sort-column'>Account Type</b> 
          <IonIcon data-testid='view-tooltip' onClick={setHeaderTooltip('Account Type')} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
          <SortingIcon render={'accountType' === assetTransactionGroupState.transactionSortingStatus.sortedColumn} currentSortingDir={assetTransactionGroupState.transactionSortingStatus.sortDirection}/>
        </p>
      </IonCol>
      <IonCol data-testid='status-column' class="table-header hover-cursor" onClick={()=>updateAssetState({type:'sort', column: 'status', index})} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
        <p>
          <b data-testid='sort-column'>Status</b>
          <IonIcon data-testid='view-tooltip' onClick={setHeaderTooltip('Status')} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
          <SortingIcon render={'status' === assetTransactionGroupState.transactionSortingStatus.sortedColumn} currentSortingDir={assetTransactionGroupState.transactionSortingStatus.sortDirection}/>
        </p>
      </IonCol>
      {completeOrInprogressActive && <CompleteAndInProgressColumns {...props} setHeaderTooltip={setHeaderTooltip}/>}
    </IonRow>
  )
}

const CompleteAndInProgressColumns: React.FC<Params & {setHeaderTooltip: SetHeaderGenerator}> = ({assetTransactionGroupState, updateAssetState, index, setHeaderTooltip}) => {
  return <>
    <TransactionDateHeader currentTab={assetTransactionGroupState.transactionSortingStatus.currentTab} updateAssetState={updateAssetState} setHeaderTooltip={setHeaderTooltip} assetTransactionGroupState={assetTransactionGroupState} index={index}/>
    <TransactionAmountHeader currentTab={assetTransactionGroupState.transactionSortingStatus.currentTab} updateAssetState={updateAssetState} setHeaderTooltip={setHeaderTooltip} assetTransactionGroupState={assetTransactionGroupState} index={index}/>
    <IonCol class="table-header" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" sizeXl="1"><p><b></b></p></IonCol>
  </>
}

export default AssetTransactionGroup