export const BUILD_ENV: 'PROD'|'QA' = process.env.REACT_APP_BUILD_ENV === 'PROD' ? 'PROD' : 'QA' //default to QA

const resolveRedirect = () => {
  const redirect = process.env.REACT_APP_REDIRECT || 'http://localhost:3000'
  if(redirect.startsWith('https')){
    return redirect
  }

  if(redirect.startsWith('http')){
    return redirect.replace('http', 'https')
  }

  return `https://${redirect}`
}

const defaultApplicationUrl = BUILD_ENV === 'PROD' ? 'https://apply.midlandtrust.com' : 'https://testapply.midlandtrust.com' 
export const APPLICATION_URL = process.env.REACT_APP_APPLICATION_URL || defaultApplicationUrl

type policyAlias = 'magicLink'|'signIn'
const REACT_APP_SIGN_IN_POLICY = process.env.REACT_APP_SIGN_IN_POLICY as Auth.LoginPolicies
const REACT_APP_ML_POLICY = process.env.REACT_APP_ML_POLICY as Auth.LoginPolicies
const PROD_APP_ID = 'a1c3ac75-8571-462e-a0a0-998665b9b218'
const QA_APP_ID = '2772554f-905e-4667-a67e-b9fab554b5cd'
const OVERRIDE_APP_ID = process.env.REACT_APP_APP_ID
const PROD_TENNENT = 'investmentsponsorportal'
const QA_TENNENT = 'testinvestmentsponsorportal'
const OVERRIDE_TENANT = process.env.REACT_APP_TENANT
const PROD_API_SERVER = "https//pro.midlandtrust.com"
const QA_API_SERVER = "https://protest.midlandtrust.com"
const OVERRIDE_API_SERVER = process.env.REACT_APP_API_SERVER

export const ERROR_CODES = {
  'USER_CANCELLATION': 'AADB2C90091',
  'MAX_PASSWORD_ATTEMPTS': 'AADB2C90157'
}
export const APP_ID = OVERRIDE_APP_ID || (BUILD_ENV === 'PROD' ? PROD_APP_ID : QA_APP_ID)
export const API_SERVER = OVERRIDE_API_SERVER || (BUILD_ENV === 'PROD' ? PROD_API_SERVER : QA_API_SERVER)
export const REDIRECT = process.env.REACT_APP_REDIRECT || 'http://localhost:3000'
export const POLICIES: {[key in policyAlias]: Auth.LoginPolicies}  = {
  magicLink: REACT_APP_ML_POLICY || 'B2C_1A_SIGNIN_WITH_MAGIC_LINK',
  signIn: REACT_APP_SIGN_IN_POLICY || 'B2C_1A_SIGNUP_SIGNIN_WITH_MAGIC_LINK'// need to reset to non dev version
}

export const TENANT = OVERRIDE_TENANT || (BUILD_ENV === 'PROD' ? PROD_TENNENT : QA_TENNENT)
export const ENVIRONMENT = {
  appId: APP_ID,
  tennent: `${TENANT}.onmicrosoft.com`
}
const tenantSubdomain = TENANT;
const instance = `https://${tenantSubdomain}.b2clogin.com/`;

export const resolveSignInAuthority = (signInPolicy: Auth.LoginPolicies) => `${instance}${ENVIRONMENT.tennent}/${signInPolicy}`;
export const resolveLogoutUrl = (signInPolicy: Auth.LoginPolicies) => `${resolveSignInAuthority(signInPolicy)}/oauth2/v2.0/logout?post_logout_redirect_uri=${REDIRECT}`
export const resolveTokenEndpoint = (signInPolicy: Auth.LoginPolicies) => `${resolveSignInAuthority(signInPolicy)}/oauth2/v2.0/token`