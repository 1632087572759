import React, {useState, useEffect, useContext, useReducer, useRef} from 'react';
import {
  IonRow,
  IonCol,
} from '@ionic/react';
import { getUserAssets } from '../../helpers/CalloutHelpers'
import SessionContext from '../../helpers/SessionContext';
import * as homeHelpers from '../../helpers/component_helpers/homeHelpers'
import { modalReducer, defaultModalState, RenderElementModal, LoadingPageModal} from './Reducer'
import '../../styles/Home.css'
import ListAssetGroups from '../AssetGroup/ListAssetGroups';

const Home: React.FC = () => {
  const [modalState, updateModalState] = useReducer(modalReducer ,defaultModalState)
  const userSession = useContext(SessionContext)
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [assetState, updateAssetState] = useReducer(homeHelpers.assetReducer,{
    assetList: [],
    statuses: []
  });
 
  let showLoadingTimeout =  useRef<NodeJS.Timeout>();
  useEffect(()=>{
    getUserAssets().then((result)=>{
      if(result.data?.status === 'ok'){
          updateAssetState({type: 'set', initialState: result.data.body})
        } 
    }).finally(
      ()=>{
        showLoadingTimeout.current = setTimeout(()=>{
          setShowLoading(false)// helps with issue around the modal stickking 
      }, 500)
    })

    return ()=>{
      showLoadingTimeout.current && clearTimeout(showLoadingTimeout.current)
    }
  },[])


  return (
    <IonRow class="container" data-testid='home-root'>
      <LoadingPageModal modalState={modalState} updateModalState={updateModalState}/>
      <RenderElementModal modalState={modalState} updateModalState={updateModalState}/>
      <IonRow style={{width: '100%'}}>
      <IonCol class="p-1" size="4">
        <h1 data-testid='user-name-header' className="page-title">Welcome {userSession.user?.name}</h1> 
      </IonCol>
      </IonRow>
      <IonCol class="p-1 lower-index" size="12">
        <ListAssetGroups assetState={assetState} updateAssetState={updateAssetState} updateModalState={updateModalState} showLoading={showLoading}/>
      </IonCol>
    </IonRow>
  );
};

export default Home;