import React, { useState, ComponentProps } from 'react'
import { IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react'
import { StandardCard } from '../../helpers/Utils'
import FileSelectButton from './FileSelectButton'
import { useForm, Controller, SubmitHandler, FieldError, Control, UseFormSetValue, UseFormSetError, ValidationRule } from 'react-hook-form'
import { uploadFileToSF } from '../../helpers/CalloutHelpers'

type FileUploadForm = {
    description: string,
    files: FileAndUploadStatus[]
}

const ShowFieldError: React.FC<{error: FieldError | undefined}> = ({error}) => {
    return error ? <IonItem lines='none' class="FieldError">{error?.message}</IonItem> : null
}

type FileUpload = {
    userInfo: sharedTypes.User | undefined,
    cusipName: string,
    finishUpload: ()=>void,
    setLocked: (state: boolean)=>void
}

const FileUpload: React.FC<FileUpload> = ({userInfo, cusipName, finishUpload, setLocked}) =>{
    const defaultValues: FileUploadForm = {
        description: '',
        files: []
    } 
    const {handleSubmit, control, watch, setValue, setError, formState} = useForm<FileUploadForm>({defaultValues, mode: 'onChange'})
    const files = watch('files')
    const [loading, setLoading] = useState<boolean>(false) 

    const uploadFiles = async (formFields: FileUploadForm) => {
        setLoading(true)
        setLocked(true)
        let errorOccurred = false
        const filesToProcess = files.filter(val=>val.status!=='uploaded')
        for(const fileToUpload of filesToProcess){
            const metaData: FileUploadRequest = {
                file: fileToUpload.file,
                metaData: {
                    cusipName,
                    description: formFields.description,
                    email: userInfo?.email || '',
                    name: userInfo?.name || ''
                }
            }
            try{
                fileToUpload.status = 'added'
                setValue('files', [...files])
                const result = await uploadFileToSF(metaData)
                if(result.status === 200){
                    fileToUpload.status = 'uploaded'
                } else {
                    errorOccurred = true
                    fileToUpload.status = 'error'
                }
            } catch(err) {
                errorOccurred = true
                console.error(err)
                fileToUpload.status = 'error'
            }
            setValue('files',[...files])
        }

        if(!errorOccurred){
            setTimeout(()=>finishUpload(), 500) 
            return

        } else {
            setError('files', {
                message: 'One or more files failed to upload. Try again by pressing Upload',
                type: 'deps'
            })
        }
        setLocked(false)
        setLoading(false)
    }

    const onValid: SubmitHandler<FileUploadForm> = (formFields) => {
        !loading && uploadFiles(formFields)
    }

    const showFileUploadFailed = () =>{
        const fieldError: FieldError | undefined = (formState?.errors?.files as any)
        if(fieldError){
            return <ShowFieldError error={fieldError} />
        }
    }
    
    const MAX_LENGTH = 255;
    const maxDescriptionLength: ValidationRule<number> = {
        message: `Description cannot exceed ${MAX_LENGTH} characters.`,
        value: MAX_LENGTH
    }

    return <>
        <StandardCard>
            <form data-testid='file-upload-form' onSubmit={(event)=>{event.preventDefault(); handleSubmit(onValid)(event)}}>
                <IonCol>
                    {showFileUploadFailed()}
                    <LabelField title='Asset' value={cusipName}/>
                    <InputField control={control} loading={loading} name={'description'} title={'Description'} validationMaxLength={maxDescriptionLength}/>
                    <FileInputField  control={control} loading={loading} setValue={setValue} setError={setError}/>
                </IonCol>
            </form>
        </StandardCard>
        <IonButton onClick={handleSubmit(onValid)} disabled={loading}>Upload</IonButton>
    </>
}

const FileInputField: React.FC<{control: Control<FileUploadForm>, loading: boolean, setValue: UseFormSetValue<FileUploadForm>, setError: UseFormSetError<FileUploadForm>}>  = ({control, loading, setValue, setError}) =>{
    const MAX_FILES = 10
    return <> 
        <IonRow>
            <Controller
                    name={'files'}
                    control={control}
                    rules={
                        {
                            required: `Please select one or more files`,
                            validate: (val)=>{
                                if(val.length > MAX_FILES){
                                    return `Only ${MAX_FILES} file(s) can be selected.`
                                }
                                return
                            }
                        }
                    }
                    render={
                        ({field: props, fieldState: {error}})=>(
                            <IonCol>
                                <FileSelectButton files={props.value} uploading={loading} filesUploadedCallback={val=>{
                                        if(val.status === 'success'){
                                            props.onChange(val.files)
                                        }
                                    }}
                                    deleteFile={(indexToDelete:number)=>{
                                        const newList = props.value.filter((val,index)=>indexToDelete != index)
                                        setValue("files",
                                            newList
                                        )
                                        if(newList.length <= MAX_FILES){
                                            setError('files', {})
                                        }
                                    }}
                                />
                                <IonRow>
                                    <ShowFieldError error={error} />
                                </IonRow>
                            </IonCol>
                        )
                    }
                />
        </IonRow> 
    </>
}

const LabelField: React.FC<{title: string, value?: string}>  = ({title, value}) =>{
    return <>
        {
            value && <IonRow>
                <IonCol>
                    <IonRow>
                        <IonLabel class="FileUpload">{title}:</IonLabel>
                    </IonRow>
                    <IonRow>
                        {value}
                    </IonRow>
                </IonCol>
            </IonRow>
        }
    </>
}

const InputField: React.FC<ComponentProps<typeof IonInput> & {name: keyof FileUploadForm, control: Control<FileUploadForm>, loading: boolean, validationPattern?: ValidationRule<RegExp>, validationMaxLength?: ValidationRule<number>}>  = ({name, title, control, type='text', loading, validationPattern, validationMaxLength}) =>{
    const registerFields = (props: any)=>{
        return {
            name: props.name,
            onIonBlur: props.onBlur,
            onIonChange: (event:any)=>{
                props.onChange(event.detail.value)
            },
            value: props.value,
            ref: props.ref //may need to modify this
        }
    }

    return <> 
        <IonRow>
            <Controller
                name={name}
                control={control}
                rules={
                    {
                        required: `Please enter a ${title?.toLowerCase()}`,
                        pattern: validationPattern,
                        maxLength: validationMaxLength
                    }
                }
                render={
                    ({field: props, fieldState: {error}})=>(
                        <IonCol>
                            <IonRow>
                                <IonLabel class="FileUpload">{title}:</IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonInput class='FileUpload' title={title} type={type} disabled={loading} {...registerFields(props)}/>
                            </IonRow>
                            <IonRow>
                                <ShowFieldError error={error} />
                            </IonRow>
                        </IonCol>
                    )
                }
            />
        </IonRow> 
    </>
}


export default FileUpload