import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import  * as homeHelpers from '../../helpers/component_helpers/homeHelpers'
import { formatNumber, StandardCard, toolTipMarkup } from '../../helpers/Utils';
import { getTransactionNotes } from '../../helpers/CalloutHelpers'
import { IonRow, IonCol, IonSpinner, IonRouterLink, IonIcon } from '@ionic/react'
import helpCircleOutline from '../../images/help-circle-outline.svg';
import ApplicationActions from './ApplicationActions';

type ContentVersion = {fileName: String, link: String} | undefined

type TransactionDetailsMarkupProps = { 
  onepathLink?: string, trans: sharedTypes.TransactionStatusItem, transDocsref: React.MutableRefObject<ContentVersion[]>
}

const TransactionDetailsMarkup: React.FC<TransactionDetailsMarkupProps> = ({trans, transDocsref, onepathLink}) => {
  const [contentVersions, setContentVersions] = useState<ContentVersion[]>([]);
  useEffect(()=>{
    if(trans.type !== 'SFTransaction') {
      return
    }
    if(transDocsref.current.length > 0){
      setContentVersions(transDocsref.current)
      return
    }

    let requestToken = axios.CancelToken.source();

    axios.get<clientApiResponse.getFileLinks>(`/api/files/${trans.id}`,{
      cancelToken: requestToken.token
    }).then(val=>{
      if(val?.data?.status === 'ok'){
        transDocsref.current = val?.data?.body.map((val)=>({
          'fileName': val[0],
          'link': val[1]
        }))
        setContentVersions(transDocsref.current)
      }else{
        setContentVersions([])
      }
    }).catch((err:Error)=>{
        if(!axios.isCancel(err)){
            setContentVersions([])
        }
    })

    return ()=>{requestToken.cancel()}
  },[trans.id, transDocsref])

  const dateLabel = trans.status === 'Complete' ? 'Date Complete' : 'Expected Date'
  const date = trans.status === 'Complete' ? trans.dateComplete : trans.expectedDate
  const amountLabel = trans.status === 'Complete' ? 'Amount Funded' : 'Expected Amount'
  const amount = formatNumber(trans.status === 'Complete' ? trans.amountFunded : trans.expectedAmount)
  const cardHeader = trans.type === 'SFTransaction' ? 'Transaction Details' : 'Application Details'
 
  return (
    <StandardCard>
      <IonRow id='main-fields' style={{display: 'block'}}>
        <IonRow>
          <h1 data-testid={`modal-title`} className="pl-3; pr-3; text-align: left;">
            {cardHeader} 
          </h1>
        </IonRow>
        <IonRow data-testid={`modal-client-name-field-${trans.id}`} class="pb-1">
          Investor Name: {trans.clientName} - {trans.accountNumber}
        </IonRow>
        <IonRow data-testid={`modal-account-type-field-${trans.id}`} class="pb-1">
          Account Type: {trans.accountType}
        </IonRow>
        <IonRow data-testid={`modal-status-field-${trans.id}`} class="pb-1">
          Status: {trans.status}
        </IonRow>
        {trans.type === 'SFTransaction' && <IonRow data-testid={`modal-date-field-${trans.id}`} class="pb-1"> {dateLabel}: {date} </IonRow>}
        {trans.type === 'SFTransaction' && <IonRow data-testid={`modal-amount-field-${trans.id}`}> {amountLabel}: {amount} </IonRow>}
      </IonRow>
      <IonRow style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
        {trans.type === 'Application' && <ApplicationActions accountNumber={trans.accountNumber} onepathLink={onepathLink} trans={trans}/>}
        {trans.type === 'SFTransaction' && <Documents contentVersions={contentVersions}/>}
      </IonRow>
    </StandardCard>
  )
}

const Documents: React.FC<{contentVersions: ContentVersion[]}> = ({contentVersions}) => {
    return <>
      <IonRow>
      {<h4 className="text-align: left">Documents:</h4>}
      </IonRow>
      {contentVersions === undefined && <IonRow>
        <IonSpinner/>
      </IonRow>}
      {contentVersions?.length === 0 &&
      <IonRow>No documents for this transaction.</IonRow>}
      {contentVersions?.map((val)=>{
        if(!val) return (<></>)
        return (
          <IonRow data-testid={`modal-file-${val.fileName}`} class="pb-1" key={val.fileName.toString()}>
            <IonRouterLink href={val.link.toString()} target="_blank" rel="noreferrer">
              Open {val.fileName}  
            </IonRouterLink>
          </IonRow>
        )
      })}
    </>
  }

const TransactionListItem: React.FC<{
    trans: sharedTypes.TransactionStatusItem,
    currentTab: StatusTabs,
    updateModalState: React.Dispatch<homeHelpers.ModalActions>,
    onePathLink?: string
}> = ({ trans, updateModalState, currentTab, onePathLink }) =>{
    const transactionNotes = useRef<String[]>()
    const transactionDocs = useRef<{link: String, fileName: String}[]>([])
    
    const setTransactionNote = async () => {
        if(!transactionNotes.current){
            updateModalState({
                type: 'startLoading'
            })
            transactionNotes.current = await getTransactionNotes(trans.id);
        }
    
        let renderNotes = transactionNotes.current?.length > 0 ? transactionNotes.current : ['No notes for this transaction.']
    
        updateModalState({
            type: 'renderElement',
            element: toolTipMarkup('Transaction Notes', renderNotes)
        })
    }
    
    const createMobileHeader = (header: string) =>( 
        <IonCol  class="mobile-table-header" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="4" sizeXl="4">
            <p>
                <b>{header}</b>
                <IonIcon onClick={()=>updateModalState({type:'renderHeaderTip', header, activeTab: currentTab})} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
            </p>
        </IonCol>
    )
    
    const TransactionDateMobileHeader: React.FC<{status: StatusTabs }> = ({status}) => {
        let headerString = status === 'In Progress' ? 'Expected Date' : 'Date Complete'
        return createMobileHeader(headerString)
    }
    
    const TransactionAmountMobileHeader: React.FC<{status: StatusTabs}> = ({status}) => {
        let headerString = status === 'In Progress' ? 'Expected Amount' : 'Amount Funded'
        return createMobileHeader(headerString)
    }

    const completeOrInprogressActive = (currentTab === 'Complete' || currentTab === 'In Progress')

    const InProgressAndCompleteColumns: React.FC = () => {
      return (<>
            <TransactionDateMobileHeader status={currentTab}/>
            <IonCol data-testid={`date-field-${trans.id}`} class="table-border" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="2" sizeXl="2">
                <p>
                    {completeOrInprogressActive ? trans.expectedDate : trans.dateComplete}
                </p>
            </IonCol>
            {trans.type === 'SFTransaction' && <TransactionAmountMobileHeader status={currentTab}/>}
            <IonCol data-testid={`amount-field-${trans.id}`} class="table-border text-right" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3" sizeXl="3">
                <p>
                    {formatNumber((completeOrInprogressActive ? trans.expectedAmount : trans.amountFunded))}
                </p>
            </IonCol>
            <IonCol class="table-border text-center" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" sizeXl="1">
                <p>
                    <IonRouterLink data-testid={`notes-link-${trans.id}`} onClick={() => { setTransactionNote() }} class="hover-cursor">Notes</IonRouterLink>
                </p>
            </IonCol>
      </>)
    }
    
    const smallSize = completeOrInprogressActive ? '2' : '4'
    return (
        <IonRow data-testid={`transaction-body-${trans.id}`} key={trans.id} class="table-section">
            <IonCol class="mobile-table-header" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    <b>Investor Name</b>
                    <IonIcon onClick={() => updateModalState({type:'renderHeaderTip', header:'Investor Name', activeTab: currentTab})} icon={helpCircleOutline} class="tooltip-danger hover-cursor" />
                </p>
            </IonCol>
            <IonCol data-testid={`client-name-field-${trans.id}`} class="table-border" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    <IonRouterLink data-testid={`client-name-link-${trans.id}`} className={'hover-cursor'} onClick={()=>{
                        updateModalState({
                            type: 'renderElement',
                            element: <TransactionDetailsMarkup trans={trans} transDocsref={transactionDocs} onepathLink={onePathLink}/>
                        })
                    }}>{trans.clientName}</IonRouterLink>
                </p>
                <p>
                  {trans.accountNumber}
                </p>
            </IonCol>
            <IonCol class="mobile-table-header" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    <b>Account Type</b>
                    <IonIcon onClick={() => updateModalState({type:'renderHeaderTip', header:'Account Type', activeTab: currentTab})} icon={helpCircleOutline} class="tooltip-danger hover-cursor" />
                </p>
            </IonCol>
            <IonCol data-testid={`account-type-field-${trans.id}`} class="table-border" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    {trans.accountType}
                </p>
            </IonCol>
            <IonCol class="mobile-table-header" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    <b>Status</b>
                    <IonIcon onClick={() => updateModalState({type:'renderHeaderTip', header:'Status', activeTab: currentTab})} icon={helpCircleOutline} class="tooltip-danger hover-cursor" />
                </p>
            </IonCol>
            <IonCol data-testid={`status-field-${trans.id}`} class="table-border" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg={smallSize} sizeXl={smallSize}>
                <p>
                    {trans.status}<IonIcon onClick={() => updateModalState({type:'renderStatusTip', status:trans.status, transactionType: trans.type})} icon={helpCircleOutline} class="tooltip-danger hover-cursor" />
                </p>
            </IonCol> 
            {completeOrInprogressActive && <InProgressAndCompleteColumns/>}
        </IonRow>
    )
}

export default TransactionListItem