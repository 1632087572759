import { isPlatform } from '@ionic/react';
import {IonContent, IonRow, IonCol} from '@ionic/react'
import logoLightMode from '../images/logo-dark.png';
import logoDarkMode from '../images/logo-light.png';

export const isMobile = () => {
    return (isPlatform('iphone') || isPlatform('android') || isPlatform('ipad'));
}

export const isPhone = () => {
  return (isPlatform('iphone') || isPlatform('android'));
}

export const getLogo = () => {
  const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDarkMode() ? logoDarkMode : logoLightMode;
};

export const formatNumber = (n: any) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
  switch(typeof n){
    case 'number':
      return formatter.format(n)
    
    case 'string':
      return formatter.format(parseFloat(n));

    default:
      //legacy can probably remove this
      return n?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}

export const StandardCard: React.FC = (props) =>(
  <IonContent class="ios lt-gray-bg">
    <div className="p-1 fill-row">
        <IonRow class="gr-border">
          <IonCol class="pl-3 pr-3 pb-1 white-bg center-modal">
            <IonCol class="modal-wrapper">
              {props.children}
            </IonCol>
          </IonCol>
        </IonRow>
    </div>
  </IonContent>
)

export const toolTipMarkup = (title: string, body: String[]) => ( 
  <StandardCard>
    <p data-testid={`tooltip-modal-body`} className="text-alight: left;">
      {body.map((item, index) => ( <IonRow className="text-alight: left;" key={index}> {item} </IonRow> ) )}
    </p>
  </StandardCard>
)

export const states = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];

export const generateNewIvestmentLink = (contactId: string) => `https://www.midlandtrust.com/financial-professionals/set-up-a-new-investment-with-midland/?ContactId=${contactId}&existingInvestement=Yes`