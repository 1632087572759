import * as homeHelpers from '../../helpers/component_helpers/homeHelpers'
import { toolTipMarkup } from '../../helpers/Utils'
import {
  IonRow,
  IonModal,
  IonIcon,
  IonPage
} from '@ionic/react';
import React from 'react';

type ModalState = {
    renderModal: boolean,
    loading: boolean,
    locked?: boolean,
    title?: string,
    markup?: JSX.Element,
    submit?: string,
}

export const defaultModalState: ModalState = {
  renderModal: false,
  loading: false,
  markup: undefined
}

export const modalReducer = (state: ModalState , action: homeHelpers.ModalActions): ModalState => {
  switch(action.type){
      case 'forceClose':
      case 'close':
        {
          if(state?.locked && action.type !== 'forceClose'){
            return state
          }
          return {renderModal: false, loading: false}
        }
      case 'startLoading':
        return {renderModal: false, loading: true}
      case 'endLoading':
        return {renderModal: false, loading: false}
      case 'lockModal':
        return {...state, locked: action.value}
      case 'renderElement':
        return {loading: false, renderModal: true, markup: action.element, title: action.title}
      case 'renderHeaderTip':
        let headerTipMarkup = toolTipMarkup(action.header, homeHelpers.headerToTooltipMessage(action?.header, action?.activeTab))
        return {loading: false, renderModal: true, markup: headerTipMarkup, title: action.header}
      case 'renderStatusTip':
        let statusTipMarkup = toolTipMarkup(action.status, homeHelpers.statusToTooltipMessage(action.status, action.transactionType))
        return {loading: false, renderModal: true, markup: statusTipMarkup, title: action.status}
      default:
        return state
    }
}

export const RenderElementModal: React.FC<{modalState: ModalState, updateModalState: React.Dispatch<homeHelpers.ModalActions>}> = ({modalState, updateModalState}) => <IonModal data-testid={`tooltip-modal`}
  cssClass="transaction-info-modal"
  backdropDismiss={false}
  keyboardClose={false}
  swipeToClose={false}
  isOpen={modalState.renderModal}
  onDidDismiss={() => updateModalState({type:'close'})} mode="ios">
  <IonRow><div className='ModalHeader'>{modalState.title}</div><IonIcon class='ModalHeader' icon='close' onClick={()=>updateModalState({type:'close'})}/></IonRow>
  {modalState.markup}
</IonModal>

export const LoadingPageModal: React.FC<{modalState: ModalState, updateModalState: React.Dispatch<homeHelpers.ModalActions>}> = ({modalState, updateModalState}) => <IonModal data-testid='loading-modal'
  backdropDismiss={false}
  swipeToClose={false}
  keyboardClose={false}
  isOpen={modalState.loading}
  cssClass="loading-modal">
  <IonPage className="modal-page">
    <div className="loading">Loading Page</div>
  </IonPage>
</IonModal>