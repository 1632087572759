import React from 'react';
import '../pages/Style.css';
import facebookIcon from '../images/facebook-icon.svg';
import twitterIcon from '../images/twitter-icon.svg';
import youtubeIcon from '../images/youtube-icon.svg';
import linkedinIcon from '../images/linkedin-icon.svg';
import {
  IonRow,
  IonCol,
  IonFooter,
  IonRouterLink,
} from '@ionic/react';
import { getLogo } from '../helpers/Utils';

const getTodaysYear = () => {
  const date = new Date();
  return date.getUTCFullYear();
}

const Footer: React.FC = () => {
  return (
    <IonFooter class="footer">
      <IonRow class="container pr-3 pl-3 mt-5 mb-1">
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
          <img src={getLogo()} width="175" alt="Equity Institutional Services logo"/>
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8">
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <p className="mt-0 mb-0 footer-contact-info"><b>ADDRESS:</b><br /> PO Box 07520<br /> Fort Myers, FL 33919</p>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <p className="mt-0 mb-0 footer-contact-info"><b>PHONE:</b> (239) 333-1032<br /><b>FAX:</b> (239) 466-5496<br /><IonRouterLink href="https://www.midlandtrust.com" target="_blank">www.midlandtrust.com</IonRouterLink></p>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size="12">
          <p className="disclaimer"><b>MARKET VALUES MAY NOT REFLECT CURRENT VALUATION:</b> Midland IRA, Inc. updates market values as they are provided. If no updated valuation has been provided to Midland IRA, Inc., market values may reflect original purchase price.</p>

          <p className="disclaimer"><b>Midland IRA, Inc. IS NOT A FIDUCIARY:</b> Midland IRA, Inc.'s role as the administrator of self-directed retirement accounts is non-discretionary and/or administrative in nature. The Accountholder or his/her authorized representative must direct all investment transactions and choose the investment(s) for the account. Midland IRA, Inc. has no responsibility or involvement in selecting or evaluating any investment. Nothing contained herein shall be construed as investment, legal, tax or financial advice or as a guarantee, endorsement, or certification of any investments.</p>
        </IonCol>
      </IonRow>
      <IonRow class="pt-1 pb-1 primary-bg footer-border-top">
        <IonRow class="soc-container pr-5 pl-5">
          <IonCol sizeXs="3" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3">
            <IonRouterLink href="https://www.facebook.com/EquityTrustCompany/" target="_blank" rel="noopener noreferrer"><img className="soc-lnk" src={facebookIcon} width="30" alt="Facebook icon"/></IonRouterLink>
          </IonCol>
          <IonCol sizeXs="3" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3">
            <IonRouterLink href="https://twitter.com/EquityTrust" target="_blank" rel="noopener noreferrer"><img className="soc-lnk" src={twitterIcon} width="50" alt="Twitter icon"/></IonRouterLink>
          </IonCol>
          <IonCol sizeXs="3" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3">
            <IonRouterLink href="https://www.youtube.com/user/EquityTrustCompany" target="_blank" rel="noopener noreferrer"><img className="soc-lnk" src={youtubeIcon} width="60" alt="YouTube icon"/></IonRouterLink>
          </IonCol>
          <IonCol sizeXs="3" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3">
            <IonRouterLink href="https://www.linkedin.com/company/equity-trust-company" target="_blank" rel="noopener noreferrer"><img className="soc-lnk" src={linkedinIcon} width="46" alt="LinkedIn icon"/></IonRouterLink>
          </IonCol>
          </IonRow>
        </IonRow>
        <IonRow class="copyright">
          <IonCol>
            <p className="white-color">&copy;{getTodaysYear()} Equity Trust Company | <a className="white-color" href="https://www.midlandtrust.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a className="white-color" href="https://www.midlandtrust.com/about-midland/" target="_blank" rel="noopener noreferrer">About</a></p>
          </IonCol>
        </IonRow>
      </IonFooter>
  );
};

export default Footer;
