import React, { useRef, useEffect} from 'react';
import { IonContent, IonHeader, IonPage, IonRow,IonCol } from '@ionic/react';
import { useParams } from 'react-router'; 
import Header from '../components/Header';
import Home from '../components/Home/Home';
import Footer from '../components/Footer';
import './Style.css';

const Main: React.FC = () => {
  const { comp } = useParams<{ comp: string; }>();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  
  const scrollToTop = () => {
    contentRef?.current && contentRef.current.scrollToTop();
  }

  useEffect(() => {
    scrollToTop();
  }, [comp]);

  const displayPage = (pageName: string) => {
    switch (pageName) {
      case 'home':
        return <Home/>;
      default:
    }
  }

  return (
    <IonPage id="main-content">
      <IonHeader>
        <Header />
      </IonHeader>
      <IonContent fullscreen ref={contentRef}>
        <IonRow class='main-page'>
          <IonCol>
            {displayPage(comp?.toLowerCase())}
          </IonCol>
        </IonRow>
        <Footer />
      </IonContent>
    </IonPage>
  )
}

export default Main;
