import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IonRow, IonButton, IonToast, IonSpinner } from '@ionic/react'
import { updateAppStatus,  sendEsignToClient, sendResumeLinkToClient } from '../../helpers/CalloutHelpers'
import { alterOnepathURL, encodeApplyLink } from '../../helpers/component_helpers/homeHelpers'
import PenSignDocument from '../PenSignDocument'

const TOAST_DURATION = 10000

type ApplicationActionsProps = {accountNumber?: string, onepathLink?: string, trans: sharedTypes.TransactionStatusItem}

const ApplicationActions: React.FC<ApplicationActionsProps> = ({accountNumber, onepathLink, trans}) => {
    type actions = 'sendResume' | 'sendEsign' | 'download'
    const [disabled, setDisabled] = useState(false)
    const [toastState, setToastState] = useState<{message: string, type: 'success'|'danger'}>()
    const [shouldConfirmRequest, setShouldConfirmRequest] = useState(false)

    const handleAction =  (action: actions)=> async ()=> {
        setDisabled(true)
        setToastState(undefined)

        if(action === 'download'){
            return
        }

        try{
            await makeCallout(action)
        }catch(err){
            if(action === 'sendEsign'){
                setToastState({message: 'Error sending Esign link to client', type: 'danger'})
            }

            if(action === 'sendResume'){
                setToastState({message: 'Error sending Resume link to client', type: 'danger'})
            }
            console.log(err)
        }
        setDisabled(false)
    }

    const makeCallout = async (action: actions) => {
        if(action === 'sendResume'){
            await sendResumeLinkToClient(accountNumber)
            // set status to 'Sent to Client'
            await updateAppStatus(trans.id, 'Sent to Client')
            setToastState({message: 'Resume link sent to client', type: 'success'})
        }

        if(action === 'sendEsign'){
            await sendEsignToClient(accountNumber)
            // set status to 'Sent for Singing'
            await updateAppStatus(trans.id, 'Sent for Signing')
            setToastState({message: 'Esign link sent to client', type: 'success'})
        }
    }

    const downloadSuccess = async ()=>{
        setToastState({message: 'Downloaded signature document', type: 'success'})
        // set status to 'Sent for Singing'
        await updateAppStatus(trans.id, 'Downloaded')
        setDisabled(false)
    }

    const downloadError = ()=>{
        setToastState({message: 'Error downloading PDF signature document', type: 'danger'})
        setDisabled(false)
    }

    const reWrittenOnePathLink = alterOnepathURL(onepathLink)
    
    const history = useHistory()

    const applicantTookOver = trans.status === 'Received' || trans.onlineAppEditedByClient

    const showEditButton = (onepathLink && trans.id) && !applicantTookOver

    const SendEsignButton: React.FC = () => {
        const handleSendEsign = async ()=>{
            const statusCheck = trans.status === 'Filled' || trans.status === 'Sent for Signing'
            if(statusCheck){
                handleAction('sendEsign')()
            } else {
                setShouldConfirmRequest(true)
            }
        }

        const confirmRequest = (confirmed: boolean) =>{
            if(confirmed){
                handleAction('sendEsign')()
            }

            setShouldConfirmRequest(false)
        }

        if(shouldConfirmRequest){
            return <IonRow style={{
                marginTop: '20px',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column'
                }}>
            <div style={{
                alignSelf: 'center',
                inlineSize: '250px',
                marginBottom: '10px',
                }}>
                Please confirm sending DocuSign request to client. 
                <br/>
                <br/>
                If the application is not fully filled in, it may disrupt the signing process and delay account opening.
            </div>
                <IonRow 
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly'
                        }}>
                    
                    <IonButton color='success' style={{flexGrow: 3}}  onClick={()=>confirmRequest(true)}>Confirm</IonButton>
                    <IonButton  color='danger' onClick={()=>confirmRequest(false)}>Cancel</IonButton>
                </IonRow>
            </IonRow>
        }
        return <IonButton  disabled={disabled} onClick={handleSendEsign}>Send Docusign Signature Request to Client</IonButton>
    }

    return <>
        <IonRow style={{alignSelf: 'center', paddingBottom: '20px'}}>
            <IonSpinner hidden={!disabled}/>
        </IonRow>
        <IonRow class='ApplicationActions'>
            <IonToast onDidDismiss={()=>{setToastState(undefined)}} duration={TOAST_DURATION} cssClass='AssetGroupToast' isOpen={Boolean(toastState)} color={toastState?.type} position={'top'} message={toastState?.message}/> 
            {showEditButton && <IonButton disabled={disabled} onClick={()=>history.push(`/apply/${encodeApplyLink(reWrittenOnePathLink, trans.id)}`)} >Edit Online Application</IonButton>}
           <SendEsignButton/> 
           <IonButton disabled={disabled || shouldConfirmRequest} onBlur={()=>{}} onClick={handleAction('sendResume')}>Send Online Application to Client</IonButton>
            <PenSignDocument disabled={disabled || shouldConfirmRequest} accountNumber={trans.accountNumber || 'not there'}
                onClick={handleAction('download')} 
                onDownloadFinished={downloadSuccess}
                onError={downloadError}
            />
        </IonRow>
    </>
}

export default ApplicationActions