import React, {useCallback, useEffect, useState, useRef} from 'react'
import { requestRefreshToken, redirectToLogin, getHashTokenType, getSignInWithMagicLink, parseError, resolveCodeFlowToPolicy } from '../helpers/authHelpers/authService'
import SessionContext from '../helpers/SessionContext';
import { resolveLogoutUrl, POLICIES } from '../helpers/authConstants'
import axios, { InternalAxiosRequestConfig } from 'axios'
import './AuthCheck.css'
import AuthCheckMessageComponent, {AuthCheckMessageComponentProps} from './AuthCheckMessageComponent';
import { getLogo } from '../helpers/Utils';

const AuthCheck: React.FC = props => {
    const [authStatus, setAuthStatus] = useState<{user?: sharedTypes.User, initSessionCalled: boolean}>({initSessionCalled: false})
    const [authCheckMessageState, setAuthCheckMessageState] = useState<AuthCheckMessageComponentProps>();

    const tokenExpRef = useRef<{expirationTime: number, timeout?: NodeJS.Timeout}>()
    const signInPolicy = useRef<Auth.LoginPolicies>()
    
    const logout = useCallback( async () => {
        try{
            let newAxiosInstance = axios.create()
            await newAxiosInstance.get('/logout')
        }catch(err){
            //noop
        }
        setAuthStatus({initSessionCalled: false })
        console.log(resolveLogoutUrl(signInPolicy.current || POLICIES.signIn))
        window.location.href = resolveLogoutUrl(signInPolicy.current || POLICIES.signIn) 
    }, [])
    
    const setErrorState = useCallback(() => setAuthCheckMessageState({
        title: 'We are having trouble logging you in.',
        content: 'Please contact our office at (239) 333-4863.',
        buttonOnClickEvent: () => logout(),
        buttonText: 'Try Again' 
    }), [logout])
    
    /**
     * Implicitly set XSRF token on every request
     * 
     * After setting the xsrf cookie and header names
     * axios will automatically copy the cookie value to the header value
     * for each request
     * this interceptor is just to make sure the param is set before
     * the request goes out
     */
    const addAuthHeader = useCallback((req: InternalAxiosRequestConfig)=>{
        req.xsrfCookieName = '__Host-XSRF-TOKEN'
        req.xsrfHeaderName = 'X-XSRF-TOKEN'
        req.withCredentials = true
        return req
    },[])

    const login = useCallback(async ()=>{
        redirectToLogin()
    }, []) 

    const setupRefresh = useCallback((expiration:number)=>{
        if(tokenExpRef.current?.timeout){
            clearTimeout(tokenExpRef.current.timeout)
        }

        tokenExpRef.current = {
            expirationTime: expiration
        }

        const earlyRefreshTime =  30 // (authStatus.tokenExp - offset);
        

        tokenExpRef.current.timeout = setTimeout(async () => {
            
            const res = await axios.get('/refresh')
            
            setupRefresh(res.data.expiresOn)
        }, earlyRefreshTime * 1000) 
    }, [])

    const initSession = useCallback(async (signInPolicy: Auth.LoginPolicies, idToken?: string, refreshToken?: string) => {
        try{
            const res = await axios.post('/init-session', { signInPolicy, idToken, refreshToken })
            setupRefresh(res.data.expiresOn)
            setAuthStatus({user: res.data.user, initSessionCalled: true})
        }catch(err){
            
            if(typeof err === 'object' && err) 
            {
                const errObj = err as any
                
                

                if(errObj.response.status === 401)
                {
                    return redirectToLogin()
                }
            }

            setErrorState()
        }
    }, [setErrorState, setupRefresh])

    /**
     * Web based login
     */
    const checkHashForWebLogin = useCallback(async (hashParams: string)=>{
        const hashType = getHashTokenType(hashParams)
        if(hashType === 'magic-link-hint'){
            try {
                const magicLink = await getSignInWithMagicLink(hashParams)
                window.location.href = magicLink
            } catch(err){
                setErrorState()
            }
            return
        }

        if(hashType === 'magic-link-code'){
            try{
                signInPolicy.current = resolveCodeFlowToPolicy(hashType)

                const refreshResponse = await requestRefreshToken(hashParams, hashType);
                
                await initSession(signInPolicy.current, refreshResponse.id_token, refreshResponse.refresh_token) //check for valid response
            }catch(err){
                setErrorState()
            }

            return
        }

        if(hashType === 'error') {
            const errorMode = parseError(hashParams)
            if(errorMode) {
                return login()
            }

            setErrorState()
        }

        return

    },[ initSession, setErrorState, login ])

    const checkForRefreshTokenLoginOnFail = useCallback(async () => {
        try {
            await initSession('B2C_1A_SIGNIN_WITH_MAGIC_LINK')
        } catch(err) {
            /**
             * if the request fails, which can happen for a variety of reasons
             * e.g. Refresh token is not saved, refresh token is stale
             * Redirect the user to login
             */
            login()
        }
    },[login, initSession])

    const completeWebBasedFlowOrInitWebBaseLogin = useCallback(()=>{
        let hashParams = window?.location?.hash 
        if(hashParams){
            checkHashForWebLogin(hashParams)
        }

        if(!hashParams && !authStatus.initSessionCalled){
            checkForRefreshTokenLoginOnFail()
        }

    }, [checkHashForWebLogin, authStatus.initSessionCalled, checkForRefreshTokenLoginOnFail, window?.location?.hash])

    // to clear any dangling timeout on unmount
    useEffect(()=>{
        return ()=>{
            tokenExpRef.current?.timeout && clearTimeout(tokenExpRef.current?.timeout)
        } 
    },[])

    useEffect(()=>{
        let subscriptionNumber = axios.interceptors.request.use(addAuthHeader)
        return ()=>{ axios.interceptors.request.eject(subscriptionNumber) }
    },[addAuthHeader])

    useEffect(() => {
        completeWebBasedFlowOrInitWebBaseLogin()
    },[ completeWebBasedFlowOrInitWebBaseLogin ])

    const renderChildren = () => {
        return React.Children.map(props.children, (child, index)=>{
            if(typeof child === 'object'){
                //lazy checking type. This should generally work
                return React.cloneElement(child as React.ReactElement<any>, {})
            }else{
                return child
            }
            //return child
        })
    }

    if (authCheckMessageState) {
        return (
            <SessionContext.Provider value={{logout}}>
                <AuthCheckMessageComponent {...authCheckMessageState} />
            </SessionContext.Provider>
        )
    }

    //handle render
    if(!authStatus.initSessionCalled){
        return (
            <div data-test='loading-animation' className='auth-container'>
                <img className='auth-center' src={getLogo()} width="100%" alt="Equity Institutional Services logo"/>
                <div className='auth-animation'/>
            </div>
        )
    } else{
        return  <SessionContext.Provider value={{user: authStatus.user, logout}}>
            {
                // will need to add auto logout
            }
            <div data-test='loading-children'>{ renderChildren() }</div>
        </SessionContext.Provider>
    }
}

export default AuthCheck