import caretDown from '../../images/arrow-down-outline.svg';
import caretUp from '../../images/arrow-up-outline.svg';
import helpCircleOutline from '../../images/help-circle-outline.svg';
import { assetActions } from '../../helpers/component_helpers/homeHelpers'
import {
  IonCol,
  IonIcon,
} from '@ionic/react';
import React, { MouseEventHandler } from 'react';

export const SortingIcon: React.FC<{
  currentSortingDir: sortingDir,
  render: boolean
}> = ({render, currentSortingDir }) => {
  return ( render ?
  (currentSortingDir === 'DESC' ? <IonIcon data-testid="sort-caret-down" icon={caretDown} class="ion-float-right"/> : 
    <IonIcon data-testid="sort-caret-up" icon={caretUp} class="ion-float-right"/>): null)
}

export const TransactionAmountHeader: React.FC<{
    currentTab: StatusTabs
    updateAssetState: React.Dispatch<assetActions>
    setHeaderTooltip: (header:string)=>MouseEventHandler<HTMLIonIconElement>
    assetTransactionGroupState: CusipAndTransactionStatusList
    index: number
}> = ({currentTab, updateAssetState, setHeaderTooltip, assetTransactionGroupState, index}) => {
    let headerString = currentTab === 'In Progress' ? 'Expected Amount' : 'Amount Funded'
    let amountTestId = currentTab === 'In Progress' ? 'expected-amount' : 'amount-funded'
    let sortingColumn: sortableTransactionColumns = currentTab === 'In Progress' ? 'expectedAmount' : 'amountFunded'
    return (
      <IonCol data-testid={`${amountTestId}-column`} class="table-header hover-cursor" onClick={()=>updateAssetState({type:'sort', column: sortingColumn, index})} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3" sizeXl="3">
            <p>
              <b  data-testid='sort-column'>{headerString}</b>
              <IonIcon data-testid='view-tooltip' onClick={setHeaderTooltip(headerString)} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
              <SortingIcon render={assetTransactionGroupState.transactionSortingStatus.sortedColumn === sortingColumn} currentSortingDir={assetTransactionGroupState.transactionSortingStatus.sortDirection}/>
            </p>
      </IonCol>
    )
}

export const TransactionDateHeader: React.FC<{
    currentTab: StatusTabs
    updateAssetState: React.Dispatch<assetActions>
    setHeaderTooltip: (header:string)=>MouseEventHandler<HTMLIonIconElement>
    assetTransactionGroupState: CusipAndTransactionStatusList
    index: number
}> = ({currentTab, updateAssetState, setHeaderTooltip, assetTransactionGroupState, index}) => {
    let headerString = currentTab === 'In Progress' ? 'Expected Date' : 'Date Complete'
    let sortingButtonId = currentTab === 'In Progress' ? 'expected-date' : 'date-complete'
    let sortingColumn: sortableTransactionColumns = currentTab === 'In Progress' ? 'expectedDate' : 'dateComplete'
    return (
      <IonCol data-testid={`${sortingButtonId}-column`} class="table-header hover-cursor" onClick={()=>updateAssetState({type:'sort', column: sortingColumn, index})} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="2" sizeXl="2">
        <p>
          <b data-testid={`sort-column`}>{headerString}</b>
          <IonIcon data-testid={`view-tooltip`} onClick={setHeaderTooltip(headerString)} icon={helpCircleOutline} class="tooltip-danger hover-cursor"/>
          <SortingIcon render={sortingColumn === assetTransactionGroupState.transactionSortingStatus.sortedColumn} currentSortingDir={assetTransactionGroupState.transactionSortingStatus.sortDirection}/>
        </p>
      </IonCol>
    )
}