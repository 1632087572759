import React, {useState} from 'react';
import '../pages/Style.css';
import {
  IonToolbar,
  IonRow,
  IonCol,
  IonButtons,
  IonMenuButton,
  IonRouterLink,
  IonGrid,
  IonActionSheet
} from '@ionic/react';
import { isMobile } from '../helpers/Utils';
import SessionContext from '../helpers/SessionContext';
import { generateNewIvestmentLink } from '../helpers/Utils'
import { getLogo } from '../helpers/Utils';

const GlobalActions: React.FC = () => {
  const userSession = React.useContext(SessionContext)
  const [openActionSheet, setOpenActionSheet] = useState<boolean>(false)
  return(
    <IonRow>
      <IonRouterLink className="hover-cursor" data-testid="global-actions-link" onClick={()=>setOpenActionSheet(true)}>I Want To...</IonRouterLink>
      <div style={{'paddingLeft': '10px', 'paddingRight': '10px'}} >|</div>
      <IonActionSheet
        data-testid="global-actions"
        onDidDismiss={()=>setOpenActionSheet(false)}
        isOpen={openActionSheet}
        cssClass="global-actions"
        buttons={[{
          text:'Create new asset',
          handler:()=>{
            window.open(generateNewIvestmentLink(userSession.user?.contactId || ''))
          }
        },
        {
          text:'Cancel',
          role:'cancel'
        }
      ]}
      />
    </IonRow>
  )
}
const Header: React.FC = () => {
  const userSession = React.useContext(SessionContext)
  return (
    <IonToolbar mode="md" className='global-header'>
        <IonGrid style={{"marginRight": "auto", "marginLeft": "auto", "maxWidth":"1250px"}} class="">
          <IonRow class="ion-justify-content-between">
            <IonCol size="3">
              <img src={getLogo()} width="160" alt="Equity Institutional Services logo"/>
            </IonCol>
            {(isMobile()) ? 
                <IonCol size="3">
                  <IonToolbar class="noshadow">
                    <IonButtons slot="end">
                        <IonMenuButton autoHide={false}  color="primary" />
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              : <IonCol size="6">
                  <IonRow class="ion-float-right">
                    <GlobalActions/>
                    <IonRouterLink className="hover-cursor" onClick={userSession.logout}>Logout</IonRouterLink>
                  </IonRow>
                </IonCol>
            }
          </IonRow>
        </IonGrid>
    </IonToolbar>
  );
};

export default Header;
