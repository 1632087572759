import React, { ChangeEventHandler, useState } from 'react';
import {
    IonItem,
    IonButton,
    IonList,
    IonIcon,
    IonLabel
  } from '@ionic/react';
import { close, checkbox, alert} from 'ionicons/icons'
import { isPlatform, IonSpinner, IonRow } from '@ionic/react';
import { readFiles } from './readFiles'

export type uploadCallBackInput = {status: 'success', files: Array<FileAndUploadStatus>} | {status: 'error', errorMessage: string}

export interface BlobWithName extends Blob {
    name: string
}

const FileIcon: React.FC<{index: number, deleteFile?: (index: number)=>void, sfFile: FileAndUploadStatus, uploading: boolean}> = ({index, deleteFile, sfFile, uploading}) =>{
    const resolveIcon = () => {
        if(sfFile.status === 'error'){
            return alert
        }
        
        if(sfFile.status === 'uploaded'){
            return checkbox
        }

        return deleteFile && close
    }

    const handleDelete = () => {
        const canBeDeleted = sfFile.status === 'added' && !uploading
        if(deleteFile && canBeDeleted){
            deleteFile(index)
        }
    }

    const cursor = (deleteFile && sfFile.status === 'added') ? 'pointer' : 'unset'
    const isLoading = sfFile.status === 'added' && uploading
    return <IonItem key={`file-${index}`} data-testid={`file-${index}`}  style={{'--padding-start': '0px', 'paddingBottom': '5px', fontSize: '14px'}}>
        {sfFile?.file?.name} 
        {!isLoading && <IonIcon data-testid="file-select-icon" style={{cursor}} onClick={handleDelete} icon={resolveIcon()} slot="end" />}
        {isLoading && <IonSpinner data-testid="file-select-loading" slot="end"/>}
    </IonItem>
}

const FileSelectButton: React.FC<{filesUploadedCallback:(fileUploadResponse: uploadCallBackInput)=>void, files:Array<FileAndUploadStatus>, uploading: boolean, deleteFile?:(fileIndex: number)=>void, fileParams?: {acceptedFiles?:string, maxSize?: number, maxSizeError?: string}}> = ({filesUploadedCallback, deleteFile, files, fileParams, uploading}) => {
    const MAX_FILE_SIZE = fileParams?.maxSize || 35000000; 
    const FILE_TOO_LARGE = `File size is too large. Files must be less than ${(MAX_FILE_SIZE/Math.pow(10, 6)).toFixed(0)} MB.`;

    const [errorMessage, setErrorMessage] = useState<string|undefined>('');

    const doesAnyFileExceedFileSizeLimit = (uploadedFilesList : Blob[]) => {
        for(let file of uploadedFilesList){
    
            if(file.size > MAX_FILE_SIZE){
                return true
            }
        }
        return false
    }

    const onFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
        try {
            setErrorMessage(undefined)
            let files = e.target.files as Array<BlobWithName> | null;
    
            if(!files){
                return
            }
            
            if (doesAnyFileExceedFileSizeLimit(files)) {
                const errorMessage = fileParams?.maxSizeError || FILE_TOO_LARGE;
    
                (document.getElementById('select_files') as any).value = '';
    
                setErrorMessage(errorMessage)
                return filesUploadedCallback({status: 'error', errorMessage: errorMessage});
            }
        
            const loadedFiles = await readFiles(files);
            
            if((document.getElementById('select_files') as HTMLInputElement | null)?.value){
                (document.getElementById('select_files') as HTMLInputElement).value = ''
            }
        
            filesUploadedCallback({status: 'success', files: loadedFiles})
        }catch(err){
            console.error(err)
        }
    } 

    
    
    const fileInputAcceptParams = () => {
        if(fileParams?.acceptedFiles){
            return fileParams?.acceptedFiles
        }
        return isPlatform('android') ? 'image/*, .doc' : '.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx';
    } 
    
    return (
        <>
            <IonButton data-testid="file-select-button" onClick={()=>{document.getElementById('select_files')?.click()}} disabled={uploading}>Select Files</IonButton>
            <input id='select_files' data-testid="file-select-button-actual" type='file' style={{'display': 'none'}} name='select_files' onChange={onFileChange} accept={fileInputAcceptParams()} multiple/>
            {(errorMessage && errorMessage !== '') &&
                (<IonItem class="mt-1 w-100" color="danger">
                  <p className="white-color">{errorMessage}</p>
                  </IonItem>)}
            <IonRow>
                <IonLabel style={{fontSize: '15px', paddingTop: '5px', paddingBottom: '15px'}}>Accepted file types: pdf, doc, docx, jpg, png, xls, xlsx.</IonLabel>
            </IonRow>
            <IonList>
                {files?.map((sfFile,index)=>{
                    return <FileIcon key={index} deleteFile={deleteFile} index={index} sfFile={sfFile} uploading={uploading}/>
                })}
            </IonList>
        </>)
}

export default FileSelectButton