import {APPLICATION_URL} from '../authConstants'

export const encodeApplyLink = (url: string, transId?: string) => {

  if(transId){
    return encodeURIComponent(btoa(`${(url)}?id=${transId}&load_session=t`))
  }
    return encodeURIComponent(btoa(`${(url)}?load_session=t`))
}

export const alterOnepathURL = (url: string|undefined) => {
  const baseUrl = APPLICATION_URL
  if(!url){
    return ''
  }
  if(baseUrl){
    const onepathToken = url.split('/')[3]
    const newOnepathLink = `${baseUrl}/${onepathToken}`
    return newOnepathLink
  }
  return url
}

export type assetActions = {
    type:'sort',
    column: sortableTransactionColumns,
    index: number
} | {
    type: 'set',
    initialState: sharedTypes.CusipAndTransactionStatusList[]
} | {
    type: 'chooseTab',
    tab: StatusTabs,
    index: number
} | {
  type: 'updateFundingInfo',
  assetId: string,
  fundingInfo: sharedTypes.AssetFundingInfo
}

export type ModalActions = {
    type: 'renderElement',
    element: JSX.Element,
    title?: string
  } | {
    type: 'renderHeaderTip',
    header: string,
    activeTab: StatusTabs,
  } | {
    type: 'renderStatusTip',
    status: statuses,
    activeTab?: StatusTabs 
    transactionType?: sharedTypes.TransactionTypes
  } | {
    type: 'startLoading' | 'endLoading' | 'close' | 'forceClose'
  } | {
    type: 'lockModal',
    value: boolean
  }

/**
 * 
 * @param trans 
 * @returns numerical score (larger number would put an item higher on a DESC ordered list)
 */
const statusScoring = (trans: TransactionStatusItem) => {
  const statusScores: {[key in Lowercase<statuses>]: number} = {
    "new": 17,
    "modified by client": 16,
    "received": 15,
    "id check failed": 15,
    "filled": 14,
    "sent for signing": 13,
    "sent to client": 12,
    "downloaded": 11,
    "pending verbal & doc approval": 10,
    "documents approved, pending verbal": 9,
    "verbal received, pending doc approval": 8,
    "pending client approval": 7,
    "documents satisfied - pending funds": 6,
    "under review - pending funds": 5,
    "pending 3rd party documents": 4,
    "capital call pending": 3,
    "complete": 2,
    "cancelled": 1
  }
  return statusScores[trans.status.toLocaleLowerCase() as Lowercase<statuses>]
}

type TransactionStatusItem = sharedTypes.TransactionStatusItem

const sortComparators: {[Property in sortableTransactionColumns]: transactionComparitor} = {
    clientName: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>valA.clientName.localeCompare(valB.clientName),
    accountType: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>(valA.accountType?.localeCompare(valB?.accountType || '') || 1),
    expectedAmount: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>((valB.expectedAmount || 0) - (valA.expectedAmount || 0)),
    amountFunded: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>((valB.amountFunded|| 0) - (valA.amountFunded|| 0)),
    expectedDate: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>(
    (new Date(valB.expectedDate||"")).getTime()) - (new Date(valA.expectedDate||"")).getTime(),
    dateComplete: (valA: TransactionStatusItem, valB: TransactionStatusItem)=>(
    (new Date(valB.dateComplete||"")).getTime()) - (new Date(valA.dateComplete||"")).getTime(),
    status:  (valA: TransactionStatusItem, valB: TransactionStatusItem)=>-statusScoring(valA) + statusScoring(valB),
  }

export function assetReducer(currentAssetState: AssetReducer, action: assetActions):AssetReducer {
    const deepCloneCusipAndTransactionStatusList = (asset: sharedTypes.CusipAndTransactionStatusList): sharedTypes.CusipAndTransactionStatusList => {
      let clonedTransactionList = asset.transactionList.map(val=>{
        return {...val}
      })
      return {...asset, transactionList: clonedTransactionList}
    }
  
    if(action.type === 'set'){
      let statusSets = new Set<statuses>()
      action.initialState.forEach(asset=>
        asset.transactionList.forEach(trans=>
          statusSets.add(trans.status)))
      return {
        assetList: action.initialState,
        statuses: Array.from(statusSets)
      }
    }
  
    if(action.type === 'sort'){
      let assetToUpdateIndex = action.index
      let assetToUpdate = deepCloneCusipAndTransactionStatusList(currentAssetState.assetList[assetToUpdateIndex])
  
      let reverseSorting = (action.column === assetToUpdate.transactionSortingStatus.sortedColumn)
      assetToUpdate.transactionList = [...assetToUpdate.transactionList]
      if(reverseSorting){
        assetToUpdate.transactionList.reverse()
        assetToUpdate.transactionSortingStatus.sortDirection = assetToUpdate.transactionSortingStatus.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      } else {
        assetToUpdate.transactionList.sort(sortComparators[action.column])
        assetToUpdate.transactionSortingStatus.sortedColumn = action.column
        assetToUpdate.transactionSortingStatus.sortDirection = 'DESC'
      }

        let assetList = [
            ...currentAssetState.assetList.slice(0, assetToUpdateIndex),
            assetToUpdate,
            ...currentAssetState.assetList.slice(assetToUpdateIndex + 1)
        ]

      return {
        ...currentAssetState,
        assetList
      }
    }
  
    if(action.type === 'chooseTab'){
        let assetToUpdate: sharedTypes.CusipAndTransactionStatusList = {
            ...currentAssetState.assetList[action.index],
            transactionSortingStatus:{
              ...currentAssetState.assetList[action.index].transactionSortingStatus,
              currentTab: action.tab
            }
        }

        return {
            ...currentAssetState,
            assetList: [
                ...currentAssetState.assetList.slice(0, action.index),
                assetToUpdate,
                ...currentAssetState.assetList.slice(+action.index + 1)
            ]
        }
    }

    if(action.type === 'updateFundingInfo'){
      let assetIndex = currentAssetState.assetList.findIndex(val=>val.asset.id === action.assetId)
      let assetToUpdate: sharedTypes.CusipAndTransactionStatusList = {
          ...currentAssetState.assetList[assetIndex],
          asset: {
            ...currentAssetState.assetList[assetIndex].asset,
            fundingInfo: action.fundingInfo
          }
        }

        return {
            ...currentAssetState,
            assetList: [
                ...currentAssetState.assetList.slice(0, assetIndex),
                assetToUpdate,
                ...currentAssetState.assetList.slice(+assetIndex + 1)
            ]
        }
    }
  
    return currentAssetState
  }

  export const headerToTooltipMessage = (header: string, tab?: StatusTabs) => {
    const completeOrInprogress = tab === 'Complete' || tab === 'In Progress'
    const draftOrInvited = tab === 'Draft' || tab === 'Invited'
    // shared headers
    switch(header){
      case 'Investor Name':
        return ['The legal name of the Equity Trust account holder.', 'The Investor/ Subscriber on the investment documents will read “Midland Trust Company as Custodian (Investor Name) (IRA #).', 'An investor may not show for one of the following reasons:', 'the Equity Trust account has not been opened;', 'a transaction has not yet been set up for this offering;', 'it was not indicated at account opening that the account holder was affiliated with this offering.']
      case 'Account Type':
        return ['The type of retirement account the investment is held in.'] 
      default: { 
        if(completeOrInprogress){
          return completeOrInprogressHeaders(header)
        }

        if(draftOrInvited){
          return draftOrInvitedHeader(header)
        }

        return [''];
      }
    }
  }

  const draftOrInvitedHeader = (header: string) => {
    switch(header){
      case 'Status':
        return ['The current status of the application.']
      default:
        return [''];
    }
  }

  const completeOrInprogressHeaders = (header: string) => {
    switch(header){
      case 'Status':
        return ['The current status of the transaction, see notes for more details.']
      case 'Expected Date':
        return ['The date Equity Trust anticipates issuing funds to the investment.', 'This date is an estimated date and is subject to change depending on any outstanding requirements.']
      case 'Expected Amount':
        return ['The forecasted funding amount.', 'This amount is an estimated amount and may be subject to change. ', 'Changes may be due to Equity Trust administrative fees, minimum cash requirements, and/ or fees/ market fluctuations experienced at the previous custodian.']
      case 'Amount Funded':
        return ['The amount that was authorized by the client to be wired to the investment.']
      case 'Date Complete':
        return ['The date the wire was sent and the asset was considered to be a new holding of their IRA.']
      default:
        return [''];
    }
  }

  export const statusToTab = (transStatus: sharedTypes.Statuses, type?: sharedTypes.TransactionTypes):StatusTabs  => {
    console.log(transStatus)
    console.log(type)
    switch(transStatus.toLocaleLowerCase() as Lowercase<sharedTypes.Statuses>) {
      case 'new':
        return type === 'Application' ? 'Draft' : 'In Progress'
      case 'filled':
        return 'Draft'
      case 'modified by client':
      case 'sent for signing':
      case 'downloaded':
      case 'received':
      case 'sent to client':
        return 'Invited'
      case 'complete':
        return 'Complete'
      default:
        return 'In Progress'
    }
  }

  export const statusToTooltipMessage = (transStatus: statuses | string, statusType?: sharedTypes.TransactionTypes ) => {
    return statusType === 'Application' ? statusToApplicationTooltipMessage(transStatus) : statusToTransactionTooltipMessage(transStatus)
  }

  const statusToTransactionTooltipMessage = (transStatus: statuses | string ) => {
    switch(transStatus.toLocaleLowerCase() as Lowercase<sharedTypes.Statuses>) {
      case 'complete':
        return ['Wire Delivery Scheduled']
      case 'cancelled':
        return ['See Notes']
      case 'under review - pending funds':
        return ['Account is opened; pending Transfer or Rollover']
      case 'documents satisfied - pending funds':
        return ['Account is opened and investment documents are in good order, may need funds and verbal']
      case 'new':
        return ['Account is open ']
      case 'pending client approval':
        return ['Investor has received an email to log on to the client portal to take action']
      case 'documents approved, pending verbal':
        return ['Account is open and funded, documents are in good order. Investor needs to call 239-900-1221']
      case 'verbal received, pending doc approval':
        return ['Account is open and funded; we have spoken with the investor and need them to complete a DocuSign request']
      case 'pending verbal & doc approval':
        return ['Account is open and funded; the investor needs to complete a DocuSign request, then call 239-900-1221']
      case 'capital call pending':
        return ['Pending receipt of Call Notice']
      case 'pending 3rd party documents':
        return ['See Notes ']
      default:
        return ['See Notes']
    }
  }

const statusToApplicationTooltipMessage = (transStatus: statuses | string ) => {
    switch(transStatus.toLocaleLowerCase() as Lowercase<sharedTypes.Statuses>) {
      case 'complete':
        return ['Account Is Open']
      case 'new':
        return ['Application has not be completed']
      case 'sent to client':
        return ['Application has been sent to the client to finish filling out and sign']
      case 'sent for signing':
        return ['Application has been sent to the client for signing']
      case 'downloaded':
        return ['Application has been downloaded for pen signature.']
      case 'filled':
        return ['Application has been fully filled but not sent to a client or downloaded for pen sign']
      case 'received':
        return ['Application has been completed by client and will be processed soon.',
        'the application is fully filled out and signed, it will be processed soon']
      case 'modified by client':
        return ['Application has been modified by client via a resume link the',
          'application cannot be modified until the client has finished signing'
        ]
      default:
        return ['Unknown Status']
    }
  }